import React from 'react';
import {Box, Button, Paper, Typography, useMediaQuery, useTheme} from '@mui/material';
import {styled} from '@mui/material/styles';
import {isMobile as isMobileDevice} from "react-device-detect";
import {useHistory} from "react-router-dom";

const HighlightPaper = styled(Paper)(({ theme, bgColor }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    margin: 'auto',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
    marginTop: theme.spacing(4),
    backgroundColor: bgColor ? bgColor : "white", // Use theme's paper background color or set your own color
    flexDirection: 'row', // Default to row
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: 12,
}));

const ImageContainer = styled(Box)(({ theme }) => ({
    flex: 1, // Allows the container to grow and take up 50% of the space
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    height: isMobileDevice ? "80vh" : 350,
    marginLeft: theme.spacing(2),
    borderRadius: 8,
    marginRight: theme.spacing(2), // Add margin to the right of the image container
    '& img': {
        width: '100%',
        height: 'auto',
        objectFit: 'cover', // Ensures the image covers the allotted space well
    },
}));

const TextContainer = styled(Box)(({ theme }) => ({
    flex: 1, // Allows the container to grow and take up 50% of the space
    padding: theme.spacing(2),
}));

export default function HomeProductFeature({ title, description, buttonText, imageUrl, imagePosition, productLink, bgColor }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const renderImageFirst = imagePosition === 'right' || isMobile;
    const history = useHistory();

    const onClick = () => {
        history.push(productLink);
    };

    const content = (
        <>
            <ImageContainer>
                <img src={imageUrl} alt={title} />
            </ImageContainer>
            <TextContainer>
                <Typography variant="h5" gutterBottom>{title}</Typography>
                <Typography variant="body1">{description}</Typography>
                {buttonText &&
                    <StyledButton variant="contained" size="large" sx={{ mt: 2 }} onClick={onClick}>
                        {buttonText}
                    </StyledButton>
                }
            </TextContainer>
        </>
    );

    return (
        <Box justifyContent="center" sx={{px: isMobile ? 0 : 20, maxWidth: isMobile ? "100vw" : "90%", mx: isMobile ? 0 : 'auto'}}>
            <HighlightPaper elevation={0} bgColor={bgColor}>
                {renderImageFirst ? content : React.cloneElement(content, {}, [...content.props.children].reverse())}
            </HighlightPaper>
        </Box>
    );
}
