import React, {useContext} from 'react';
import {styled} from '@mui/styles';
import CatalogContext from "./catalogContext";
import {Chip} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import PlatformSpace from "../../components/platform/platformSpace";
import {isMobile} from "react-device-detect";

export default function CatalogTableChips(props) {
    const {filters, toggleFilter, setMobileFilterOpen} = useContext(CatalogContext);

    const onDeleteFilter = (category, value) => {
        toggleFilter(category, value);
    }

    const onMobileFilter = () => {
        setMobileFilterOpen(true);
    }

    return (
        <React.Fragment>
            <PlatformSpace height={20}/>
            <Grid sx={{minHeight: 50}} container justifyContent="center" alignItems="center" rowSpacing={2} spacing={1}>
                {Object.keys(filters).map((key) => {
                    return filters[key].map((value, idx) => {
                        return (
                            <Grid item key={`${key}-${value}-${idx}`}>
                                <FilterChip
                                    label={value}
                                    onClick={onMobileFilter}
                                    onDelete={() => onDeleteFilter(key, value)}
                                />
                            </Grid>
                        );
                    });
                })}
            </Grid>
            {isMobile && <PlatformSpace heigh={10}/>}
        </React.Fragment>
    );
}

const FilterChip = styled(Chip)(({ theme }) => ({
    fontSize: "0.8rem !important",
    fontFamily: "Poppins !important",
    color: theme.custom.primaryTextColor,
    fontWeight: 700
}));