import React from 'react';
import {styled} from '@mui/material/styles';
import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function HomeWhatWeDo() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const BackgroundImageBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
        background: `url(${process.env.PUBLIC_URL}/images/hh/WhatWeDo.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        borderRadius: theme.shape.borderRadius,
        padding: isMobile ? theme.spacing(4) : `50px 20vw 50px 20vw`,
        [theme.breakpoints.up('md')]: {
            minHeight: '500px',
        },
    }));

    const ImageBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.up('md')]: {
            order: 2, // Put the image on the right side
            width: '50%',
        },
        '& img': {
            width: '100%',
            height: 'auto',
        },
    }));

    const ContentBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.up('md')]: {
            order: 1, // Put the text on the left side
            width: '50%',
        },
    }));

    return (
        <BackgroundImageBox>
            <ImageBox>
                {/* Image placeholder or content */}
            </ImageBox>
            <ContentBox>
                <Typography variant="h3" gutterBottom>
                    What We Do
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ mb: 3 }}>
                    We produce premium cannabis products that can be legally delivered directly to your doorstep.
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    <CheckCircleIcon color="success" />
                    <Typography variant="body1">
                        100% Federally Legal
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    <CheckCircleIcon color="success" />
                    <Typography variant="body1">
                        American Grown
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    <CheckCircleIcon color="success" />
                    <Typography variant="body1">
                        Discreet Packaging
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    <CheckCircleIcon color="success" />
                    <Typography variant="body1">
                        Since 2018
                    </Typography>
                </Box>
            </ContentBox>
        </BackgroundImageBox>
    );
}
