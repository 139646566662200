import API from "api";

export default {

    createMessage(request, successCallback, errorCallback) {
        API.POST({
            url: `/contact/message`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    subscribeEmail(email, successCallback, errorCallback) {
        API.POST({
            url: `/notification/subscriber/email/opt-in`,
            params: {email: email}
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    fetchProducts(queryText, successCallback, errorCallback) {
        API.GET({
            url: `/index/products/cannabis/fetch/${queryText}`,
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    getProductsGroupedByCategory(successCallback, errorCallback) {
        API.GET({
            url: `/index/categories`,
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    getCategories(successCallback, errorCallback) {
        API.GET({
            url: `/categories`,
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },
}
