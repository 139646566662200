import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {isDesktop, isMobile} from "react-device-detect";
import PlatformMarginBox from "../../components/platform/platformMarginBox";
import {useHistory} from "react-router-dom";
import PlatformSpace from "../../components/platform/platformSpace";

const StyledImageBox = styled(Box)(({ theme }) => ({
    width: isMobile ? "92vw" : "100vw",
    height: isMobile ? "60vh" : "35vh",  // Updated height to 30% of the viewport height
    maxHeight: isMobile ? "inherit" : 400,
    borderRadius: 20,
    position: 'relative',
}));

const ImageOverlay = styled(Box)(({ theme, justifyText = 'flex-start' }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: justifyText,
    justifyContent: 'center',
    color: 'white',
    px: 2,
    borderRadius: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: 12,
    maxWidth: 325
}));

export default function HomeFeature2(props) {
    const history = useHistory();

    const onShopAll = () => {
        history.push("/catalog")
    }

    return (
      <PlatformMarginBox>
          <StyledImageBox>
              <img
                src={`${process.env.PUBLIC_URL}/images/hh/100PercentLegal.jpg`}
                alt="Cannabis leaves"
                style={{ width: '100%', height: '100%', borderRadius: '20px' }}
              />
              <ImageOverlay justifyText="flex-start">
                  <Box sx={{width: isMobile ? "80%" : "65%", margin: isMobile ? 3 : 5}}>
                      <Typography variant={isMobile ? "h4" : "h3"} gutterBottom sx={{color: "white !important"}}>
                          100% Federally Legal THC from Small U.S. Farms
                      </Typography>
                      {isMobile &&
                        <React.Fragment>
                            <Typography variant="h6" sx={{color: "white !important"}}>
                                ✦ Discreet Delivery
                            </Typography>
                            <Typography variant="h6" sx={{color: "white !important"}}>
                                ✦ No Med Card Required
                            </Typography>
                            <Typography variant="h6" sx={{color: "white !important"}}>
                                ✦ Trusted by 50k+ people
                            </Typography>
                            <PlatformSpace height={20}/>
                        </React.Fragment>
                      }
                      {isDesktop &&
                        <Typography variant="h6" sx={{color: "white !important"}}>
                            Discreet Delivery ✦ No Med Card Required ✦ Trusted by 50k+ people
                        </Typography>
                      }
                      <StyledButton variant="contained" size="medium" sx={{ mt: 2 }} onClick={onShopAll}>
                          Shop now →
                      </StyledButton>
                  </Box>
              </ImageOverlay>
          </StyledImageBox>
      </PlatformMarginBox>
    );
};
