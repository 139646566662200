import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

export default function HomeFAQ() {
    const faqs = [
        {
            question: "How do you create the different effects?",
            answer: "We select different cannabis strains that are known to produce specific effects. Our selection is based on the unique cannabinoid profiles and terpenes, which influence the overall effects."
        },
        {
            question: "Lab testing",
            answer: "Greenbelt subjects all products to extensive lab tests to confirm their purity and strength. Tests include analyses for cannabinoids, terpenes, pesticides, and other impurities."
        },
        {
            question: "Will this show up on a drug test?",
            answer: "THC levels can vary in the body based on usage frequency. There is a possibility that our THC-containing products could be detected in a drug test."
        },
        {
            question: "Getting high: what our cannabis feels like",
            answer: "The effects of our cannabis can differ widely based on the strain and individual biochemistry. Typically, effects range from relaxation and euphoria to heightened energy and creativity."
        },
    ];

    return (
        <Container maxWidth="lg">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <Typography variant="h4" gutterBottom sx={{ paddingLeft: 2 }}>
                        FAQs
                    </Typography>
                    {faqs.map((faq, index) => (
                        <Accordion key={index}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{faq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body4">
                                    {faq.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>

                <Box
                    sx={{
                        flex: 1,
                        display: { xs: 'none', md: 'block' },
                    }}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/images/hh/FAQ.jpg`}
                        alt="Cannabis leaves"
                        style={{ width: '100%', height: 'auto', borderRadius: '20px' }}
                    />
                </Box>
            </Box>
        </Container>
    );
}
