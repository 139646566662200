import React from "react";
import PropTypes from "prop-types";
import {makeStyles, withStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import styles from "./platformStyles";
import cx from "classnames";

const useStyles = makeStyles(styles);

export default function PlatformButton(props) {
    const { children, fullWidth, size, onClick, ...allProps } = props;
    const classes = useStyles();

    let combinedClassName = cx({
        [classes.platformButtonSmall]: size && size === "sm",
        [classes.platformButtonLarge]: size && size === "lg",
        [classes.platformButtonFullWidth]: fullWidth,
        [classes.platformButton]: true
    });

    return (
        <ColorButton
            {...allProps}
            onClick={onClick}
            disableElevation
            color="primary"
            classes={{
                root: combinedClassName,
            }}
            variant="contained">
            {children}
        </ColorButton>
    );
}

const ColorButton = withStyles((theme) => ({
    root: {
        color: "white !important",
        backgroundColor: "#5cdb94",
        '&:hover': {
            backgroundColor: "#389583",
        },
    },
}))(Button);

PlatformButton.propTypes = {
    children: PropTypes.any
}