import React, {useContext, useState} from 'react';
import {Box, Grid, Link, Paper, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {styled} from "@mui/styles";
import PublicMegaMenuContext from "./megaMenu/publicMegaMenuContext";
import PublicContext from "./publicContext";
import AppContext from "../../appContext";

export default function PublicShopSimpleMenu(props) {
    const theme = useTheme();
    const { hideAnchor } = useContext(PublicMegaMenuContext);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [hoveredCategory, setHoveredCategory] = useState(null);
    const {categories} = useContext(AppContext);

    const handleMouseEnter = (category) => {
        setHoveredCategory(category);
    };

    const handleMouseLeave = () => {
        setHoveredCategory(null);
    };

    return (
      <Stack direction="row" spacing={0} justifyContent="center" alignItems="center">
          <LearnSection>
              <Grid container spacing={4}>
                  <Grid item xs={12} md={4}>
                      {(hoveredCategory && hoveredCategory !== 'All Products') &&
                          <ImagePaper elevation={isSmallScreen ? 0 : 4}>
                              <Image
                                src={hoveredCategory?.tileImage?.mediaUrl}
                                alt={hoveredCategory?.name || 'Default'}
                              />
                          </ImagePaper>
                      }
                  </Grid>
                  <Grid item xs={12} md={8}>
                      <LearnLinks>
                          <Typography variant="body4" gutterBottom>
                              SHOP THC
                          </Typography>
                          <StyledLink
                            href="/catalog"
                            variant="h6"
                            gutterBottom
                            onMouseEnter={() => handleMouseEnter('All Products')}
                            onMouseLeave={handleMouseLeave}
                          >
                              All Products
                          </StyledLink>
                          {categories?.map((category) => (
                            <StyledLink
                              href={`/catalog/${category.name.toLowerCase()}`}
                              variant="h6"
                              gutterBottom
                              onMouseEnter={() => handleMouseEnter(category)}
                              onMouseLeave={handleMouseLeave}
                            >
                                {category.name}
                            </StyledLink>
                          ))}
                      </LearnLinks>
                  </Grid>
              </Grid>
          </LearnSection>
      </Stack>
    );
}

const LearnSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    width: "90vw"
}));

const ImagePaper = styled(Paper)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    height: '100%',
}));

const Image = styled('img')({
    display: 'block',
    width: '100%',
    height: 'auto',
});

const LearnLinks = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}));

const StyledLink = styled(Link)({
    color: 'rgba(9,45,33,0.9) !important',
    cursor: "pointer !important",
    textDecoration: "none !important",
    '&:hover': {
        textDecoration: 'underline !important',
    },
    margin: '8px 0',
});
