import * as React from 'react';
import {useContext} from 'react';
import Typography from "@mui/material/Typography";
import PlatformSpace from "../../components/platform/platformSpace";
import PlatformTextField from "../../components/platform/platformTextField";
import PlatformCheckbox from "../../components/platform/platformCheckbox";
import CheckoutAddressForm from "./checkoutAddressForm";
import usePlatformForm from "../../components/hook/usePlatformForm";
import {isDesktop} from "react-device-detect";
import {Alert} from "@mui/material";
import CartContext from "../cart/cartContext";
import AddressRecommendationIndex from "./validate/addressRecommendationIndex";
import CheckoutContext from "./checkoutContext";

export default function CheckoutStep1Information(props) {
    const {
        recommendedAddress,
        recommendedAddressOpen,
        onRejectRecommendation,
        onAcceptRecommendation,
        addressValidated,
    } = useContext(CheckoutContext);

    if (isDesktop) {
        usePlatformForm();
    }

    const onAccept = () => {
        onAcceptRecommendation();
    }

    const onReject = () => {
        onRejectRecommendation();
    }

    return (
      <React.Fragment>
          <Typography variant="h8">
              Contact
          </Typography>
          <PlatformSpace height={10}/>
          <PlatformTextField
            name="email"
            errorText="Email is required"
            placeholder="Email address"
            emailOrPhone
            required
            fullWidth
          />
          <PlatformSpace height={10}/>
          <PlatformCheckbox
            label="Email me with news and offers"
            dense
            reversed
            defaultValue={true}
            name="optIn"
          />
          <PlatformSpace height={30}/>
          <Typography variant="h8">
              Shipping address
          </Typography>
          <PlatformSpace height={10}/>
          <CheckoutAddressForm groupBy="shippingAddress"/>
          {addressValidated === "INVALID" &&
            <React.Fragment>
                <PlatformSpace height={10}/>
                <Alert severity="error">The address could not be verified. Please correct it and try again.</Alert>
            </React.Fragment>
          }
          <AddressRecommendationIndex open={recommendedAddressOpen} address={recommendedAddress}
                                      acceptHandler={onAccept} rejectHandler={onReject}/>
      </React.Fragment>
    )
}