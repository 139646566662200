import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import {isMobile} from "react-device-detect";
import {useHistory} from "react-router-dom";

const BackgroundImageBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/hh/WhereItAllBegan.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: isMobile ? 20 : 0,
    height: isMobile ? "inherit" : 550
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row', // Default to row layout
    alignItems: 'stretch',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '20px',
    overflow: 'hidden',
    maxHeight: isMobile ? "inherit" : 500,
    maxWidth: isMobile ? "90vw" : "60vw",
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column', // Stack vertically on small screens
    },
}));

const TextContentBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    width: isMobile ? "inherit" : "50%",
    [theme.breakpoints.down('md')]: {
        order: 2, // Text below image on small screens
    },
}));

const ImageBox = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/hh/ATX.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.up('md')]: {
        width: '50%', // Take up half the width on medium screens and up
    },
    [theme.breakpoints.down('md')]: {
        width: '100%', // Take full width on small screens
        height: '300px', // Set a fixed height on small screens
        order: 1, // Image above text on small screens
    },
}));

const AboutButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
    },
    color: 'white',
    textTransform: 'none',
    borderRadius: 12,
}));

export default function HomeAboutUs() {
    const history = useHistory();

    const onAboutUs = () => {
        history.push("/about-us")
    }

    return (
        <BackgroundImageBox>
            <ContentWrapper>
                <ImageBox />
                <TextContentBox>
                    <Typography variant="h4" gutterBottom>
                        The Origin Story
                    </Typography>
                    <Typography variant="body1" paragraph>
                        It all started in the vibrant city of Austin, TX, where two friends united by a vision to promote sustainable practices launched our pioneering venture.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        As we progressed, we established ourselves as leaders in the field, crafting exceptional, hemp-derived cannabis products and advocating for environmental sustainability. Our commitment to community engagement has remained unwavering from the outset.
                    </Typography>
                    <AboutButton variant="contained" onClick={onAboutUs}>
                        MORE ABOUT US
                    </AboutButton>
                </TextContentBox>
            </ContentWrapper>
        </BackgroundImageBox>
    );
}
