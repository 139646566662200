import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/styles";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";

export default function PublicHeaderTab(props) {
    const {text, selected, ...allProps} = props;
    const [hover, setHover] = useState(false);
    const history = useHistory();
    const onHoverIn = () => setHover(true);
    const onHoverOut = () => setHover(false);

    return (
        <div onClick={() => history.push("/catalog")} style={{display: "inline-block"}}
             onMouseOver={onHoverIn} onMouseOut={onHoverOut} {...allProps}>
            <TabButton selected={selected || hover}>
                <TabText variant="button" sx={{fontWeight: 700}}>
                    {text}
                </TabText>
            </TabButton>
        </div>
    )
}

const TabText = styled(Typography)(({ theme }) => ({
    fontSize: isMobile ? "0.9rem !important" : "inherit",
    whiteSpace: 'nowrap',
}));

const TabButton = styled('div')(({ theme, selected }) => ({
    padding: "0 16px 0 16px",
    cursor: "pointer",
    color: theme.custom.primaryTextColor,
    borderRadius: 10,
    ...(selected && {
        transition: "background .15s ease-out",
        background: "rgba(255, 255, 255, 0.4)"
    }),
}));