import React, {useContext, useEffect} from "react";
import {PublicContextProvider} from "./publicContext";
import PlatformSpace from "../platform/platformSpace";
import AppContext from "../../appContext";
import {styled} from "@mui/styles";
import PublicCart from "./publicCart";
import PublicBackdrop from "./publicBackdrop";
import {StickyContainer} from "react-sticky";
import {CartContextProvider} from "../../views/cart/cartContext";
import PublicContactIndex from "./contact/publicContactIndex";
import PublicSearchMobile from "./publicSearchMobile";
import useThrottledScrollOffset from "../hook/useThrottleScrollOffset";
import AgeGateHome from "../../views/public/agegate/ageGateHome";
import PublicPromoBanner from "./publicPromoBanner";
import {isMobile} from "react-device-detect";
import CatalogItemDialog from "../../views/catalog/item/dialog/catalogItemDialog";

export default function PublicLayout(props) {
    const {fullWidth, children} = props;
    const {layoutOptions} = useContext(AppContext);
    const offset = useThrottledScrollOffset();
    const BackgroundComponent = layoutOptions.backgroundComponent;
    const FooterComponent = layoutOptions.footerComponent;
    const HeaderComponent = layoutOptions.headerComponent;
    const {video, blur = 0, topMargin = isMobile ? 135 : 80, backgroundOpacity = 1} = layoutOptions;

    useEffect(()  => {
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundSize = "100% 50%, 100% 50%, 200% 200%, 200% 200%";
        document.body.style.backgroundPosition = "0% 100%, 0% 0%, 0% 0%, 0% 0%";
        document.body.style.backgroundColor = "#f7f6f2"; // #fef7ee
        //
        // document.body.style.backgroundImage = isMobile ?
        //     "radial-gradient(circle at bottom center, transparent 50%, white 100%)," +
        //     "radial-gradient(circle at top center, transparent 50%, white 100%)," +
        //     "radial-gradient(circle at bottom right, transparent 50%, rgba(173, 216, 230, 0.2) 100%)," +
        //     "radial-gradient(circle at -30% 50%, transparent 50%, rgba(0, 255, 0, 0.2) 100%)" :
        //     // Start Desktop
        //     "radial-gradient(circle at bottom center, transparent 50%, white 100%)," +
        //     "radial-gradient(circle at top center, transparent 50%, white 100%)," +
        //     "radial-gradient(circle at bottom right, transparent 50%, rgba(173, 216, 230, 0.2) 100%)," +
        //     "radial-gradient(circle at -30% 50%, transparent 50%, rgba(0, 255, 0, 0.2) 100%)";

        // Purple Erkle
        // document.body.style.backgroundImage =
        //     "linear-gradient(135deg, rgb(131, 99, 161) 0%, rgb(116, 168, 195) 100%)," +
        //     "linear-gradient(135deg, rgb(131, 99, 161) 0%, rgb(116, 168, 195) 100%)"
    }, [layoutOptions]);

    return (
        <React.Fragment>
            <AgeGateHome/>
            <PublicContextProvider>
                <CartContextProvider>
                    { layoutOptions.backgroundComponent &&
                        <BackgroundComponent offset={offset} video={video} backgroundOpacity={backgroundOpacity}/>
                    }
                    { layoutOptions.headerComponent &&
                        <React.Fragment>
                            <HeaderComponent offset={offset}/>
                        </React.Fragment>
                    }
                    <PublicPromoBanner>
                        <div style={{backdropFilter: `blur(${isMobile ? 0 : blur})`}}>
                            {layoutOptions.headerComponent && <PlatformSpace height={topMargin}/>}
                            <PublicBackdrop/>
                            <StickyContainer>
                                <ChildrenContainer>
                                    {children}
                                </ChildrenContainer>
                            </StickyContainer>
                        </div>
                    </PublicPromoBanner>
                    { layoutOptions.footerComponent &&
                        <FooterComponent offset={offset}/>
                    }
                    { !layoutOptions.footerComponent &&
                        <PlatformSpace height={100}/>
                    }
                    <PublicCart/>
                    <PublicSearchMobile/>
                    <PublicContactIndex/>
                    <CatalogItemDialog/>
                </CartContextProvider>
            </PublicContextProvider>
        </React.Fragment>
    );
}

const ChildrenContainer = styled('div')(({ fullWidth }) => ({
    margin: "auto",
}));