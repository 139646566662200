import React, {useContext} from "react";
import Typography from "@mui/material/Typography";
import {Divider, Stack} from "@mui/material";
import {styled} from "@mui/styles";
import CheckoutContext from "./checkoutContext";
import PlatformFormContext from "../../components/platform/platformFormContext";
import formatAddress from "../../components/util/addressUtil";

export default function CheckoutStatus(props) {
    const {wizardStep, setWizardStep} = useContext(CheckoutContext);
    const {getFormGroupValue, getFormValue} = useContext(PlatformFormContext);
    const shippingAddressArray = getFormGroupValue("shippingAddress");
    const formattedAddress = formatAddress(shippingAddressArray);
    const email = getFormValue("email");

    return (
        <ProcessContainer>
            <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={2}>
                    <Typography variant="body5">
                        Contact
                    </Typography>
                    <Typography variant="body2">
                        {email}
                    </Typography>
                </Stack>
                <ChangeLink variant="body5" onClick={() => setWizardStep(1)}>
                    Change
                </ChangeLink>
            </Stack>
            <CartDivider/>
            <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={2}>
                    <Typography variant="body5">
                        Ship to
                    </Typography>
                    <Typography variant="body2">
                        {formattedAddress}
                    </Typography>
                </Stack>
                <ChangeLink variant="body5" onClick={() => setWizardStep(1)}>
                    Change
                </ChangeLink>
            </Stack>
            {wizardStep > 2 &&
                <React.Fragment>
                    <CartDivider/>
                    <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={2}>
                        <Typography variant="body5">
                            Method
                        </Typography>
                        <Typography variant="body2">
                            Standard · Free
                        </Typography>
                    </Stack>
                </React.Fragment>
            }
        </ProcessContainer>
    );
}

const ChangeLink = styled(Typography)(({  }) => ({
    color: "#5492D9FF !important",
    textDecoration: "underline",
    cursor: "pointer"
}));

const ProcessContainer = styled("div")(({  }) => ({
    border: "1px solid rgba(59,42,84, 0.80)",
    borderRadius: 12,
    width: "100%",
    overflow: "hidden",
    padding: "10px 20px"
}));

const CartDivider = styled(Divider)(({ fullWidth }) => ({
    borderColor: "rgba(64, 64, 64, 0.5) !important"
}));