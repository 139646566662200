import React, {createContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import _ from "lodash";

export const PlatformFormContext = createContext({
    setFormValue: () => {},
    getFormValue: () => {},
    getFormValueDTO: () => {},
    getFormGroupValue: () => {},
    validateForm: () => {},
    getForm: () => {},
    setForm: () => {}
});
export default PlatformFormContext;

let changeCallback = () => {};

export function PlatformFormContextProvider(props) {
    let {name, children, onChange, onReady, dirtyHandler, debug} = props;
    const formRef = useRef();
    const [fields, setFields] = useState([]);
    const [fieldsDirty, setFieldsDirty] = useState(false);
    const [form, setForm] = useState({});
    const mounted = useRef(false);

    if (debug) {
        console.log(" *** Platform form loaded:", name, " *** ")
    }

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (Object.keys(form).length > 0) {
            if (typeof onChange === "function") {
                onChange(form);
            }

            onDirty(form);

            if (typeof changeCallback === "function") {
                changeCallback(form);
            }
        }
    }, [form])

    useEffect(() => {
        if (mounted.current === true) {
            if (typeof onReady === "function") {
                setTimeout(() => {
                    onReady();
                }, 50)
            }
        }
    }, [mounted.current])

    useImperativeHandle(formRef, () => ({
        getName() {
            return name;
        },

        reloadForm(timeout) {
            setTimeout(() => {
                formRef.current.setForm(formRef.current.getForm());
            }, timeout || 100)
        },

        resetFields() {
            setFields([]);
        },

        resetForm() {
            setFields([]);
            setForm({});
        },

        onChange(name, value, checked, groupBy) {
            if (debug) {
                console.log('name', name, 'value', value, 'groupBy', groupBy);
            }

            let changedField = null;

            if (!groupBy) {
                form[name] = value || checked;
            } else {
                let collection = form[groupBy] || [];
                let fieldIndex = collection.findIndex(c => c.name === name);

                if (fieldIndex !== -1) {
                    changedField = collection[fieldIndex];
                    collection[fieldIndex].value = value;
                } else {
                    changedField = {name: name, value: value};
                    collection.push({ name, value });
                }
                form[groupBy] = collection;
            }

            if (Object.keys(form).length > 0) {
                if (typeof onChange === "function") {
                    onChange(form, fieldsDirty);
                    onDirty(form);
                }
            }

            if (changedField) {
                if (typeof changeCallback === "function") {
                    changeCallback(changedField, groupBy);
                }
            }

            // Find the corresponding field by name and groupBy
            const field = fields.find(field => field.name === name && (groupBy ? field.groupBy === groupBy : true));

            if (field && typeof field.validate === 'function') {
                setTimeout(() => {
                    field.validate(); // Call the validate() function on the field
                }, 100)
            }
        },

        onBlur(name, event) {
            fields.forEach((field) => {
                if (field.name === name && form[name]) {
                    field.validate();
                }
            });
        },

        setValue(name, value, groupBy) {
            if (!groupBy) {
                form[name] = value;

                const field = fields[name];
                if (field && typeof field.setValue === "function") {
                    field.setValue(value, true);
                } else if (debug) {
                    console.log('Platform component missing setValue', name, fields);
                }
            } else {
                let collection = form[groupBy] || [];

                // Check if collection is an array
                if (Array.isArray(collection)) {
                    collection.forEach(c => {
                        const field = fields.find(f => f.name === c.name);
                        const newValue = value.find(f => f.name === c.name);
                        c.value = newValue.value
                        if (field && typeof field.setValue === "function") {
                            field.setValue(c.value, true);
                        } else if (debug) {
                            console.log('Platform component missing setValue', name, fields);
                        }
                    });
                } else if (typeof collection === 'object') {
                    // Added functionality for object type collection
                    collection[name] = value;
                }
                form[groupBy] = collection;

                // Asynchronous update handling
                if (Object.keys(form).length > 0 && typeof onChange === "function") {
                    onChange(form, fieldsDirty);
                }
            }
        },

        isOffScreen(name, groupBy) {
            // Find the field by name and groupBy
            const field = fields.find(field => field.name === name && field.groupBy === groupBy);

            // If the field is found, return the unbound value
            if (field) {
                return field.offScreen;
            }

            // Return a default value (such as null) if the field is not found
            return null;
        },

        isUnbound(name, groupBy) {
            // Find the field by name and groupBy
            const field = fields.find(field => field.name === name && field.groupBy === groupBy);

            // If the field is found, return the unbound value
            if (field) {
                return field.unbound;
            }

            // Return a default value (such as null) if the field is not found
            return null;
        },

        getGroupValueAsObject(name, groupBy) {
            let value = this.getValue(name, groupBy);
            let retVal = null;
            if (value && value.length > 0) {
                retVal = {};
                for (let item of value) {
                    retVal[item.name] = item.value;
                }
            }
            return retVal;
        },

        getValue(name, groupBy) {
            // Handle grouped values
            if (groupBy) {
                if (!form || !form[groupBy]) {
                    return null;
                }
                const group = form[groupBy];

                // Check if group is an array or an object
                if (Array.isArray(group)) {
                    // Existing functionality for array type group
                    const item = group.find(c => c.name === name);
                    return item ? item.value : null;
                } else if (typeof group === 'object') {
                    // Added functionality for object type group
                    return group[name] ? group[name] : null;
                }
            }

            // Handle top-level values
            if (form[name] === 0 || form[name] === 0.0) {
                return form[name];
            }

            if (!form || !form[name]) {
                return null;
            }

            return form[name];
        },

        getGroupValue(groupName) {
            // Get the group value from the form state
            const group = form[groupName];

            // If the group is not found or is not an array, return an empty array
            if (!Array.isArray(group)) {
                return [];
            }

            // Return the group as it is, assuming it's already in the desired format
            return group;
        },

        setDirty(dirty) {
            setFieldsDirty(dirty);
        },

        setForm(form) {
            if (debug) {
                console.log('setForm', form);
            }

            for (const fieldKey of Object.keys(fields)) {
                const field = fields[fieldKey];

                for (const formKey of Object.keys(form)) {
                    const formValue = form[formKey];

                    if (Array.isArray(formValue)) {
                        // Handle groupBy values
                        for (const groupItem of formValue) {
                            if (groupItem.name === field.name) {
                                if (typeof field.setValue === "function") {
                                    field.setValue(groupItem.value);
                                } else {
                                    console.log('Platform component missing setValue', groupItem.name);
                                }
                            }
                        }
                    } else {
                        // Handle top-level values
                        if (formKey === field.name) {
                            if (typeof field.setValue === "function") {
                                field.setValue(formValue);
                            } else {
                                console.log('Platform component missing setValue', formKey);
                            }
                        }
                    }
                }
            }
            setForm(form);
        },

        getForm() {
            if (debug) {
                console.log('getForm', form)
            }
            let retVal = _.clone(form);
            for (const fieldKey of Object.keys(fields)) {
                const field = fields[fieldKey];
                if (field.unbound === "true") {
                    retVal[field.name] = null;
                    delete retVal[field.name];
                } else if (retVal[field.name] === false) {
                    // Handle boolean false value
                    retVal[field.name] = false;
                }
            }
            return retVal;
        },

        validate(scrollToField) {
            let valid = true;
            let scrollField = null;
            fields.forEach((field) => {
                // Skip validation for fields where unbound is "true"
                // Note: This doesn't work because we need the isValid field to render errors on view
                // if (field.unbound === "true") {
                //     return;
                // }
                let result = field.validate();
                if (!result) {
                    console.log('Failed validation ', field);
                    valid = false;
                    if (!scrollField) {
                        scrollField = field;
                    }
                }
            });
            if (scrollField) {
                //if (scrollToField === true) {
                console.log('scrolling to field', scrollField.name, 'scrollToField', scrollToField);
                setTimeout(() => {
                    scrollField.onScrollIntoView();
                }, 125)
                //}
            }
            return valid;
        }
    }));

    const resetForm = () => {
        setFields([]);
        setForm({});
    }

    const addField = (fieldName, field) => {
        if (field.unbound === true) {
            if (debug) {
                console.log('Skipping unbound field ' + fieldName);
            }
            return;
        }
        if (debug) {
            console.log('addField', name, 'name', fieldName);
        }

        // Find the index of an existing field with the same name and groupBy
        const existingFieldIndex = fields.findIndex(existingField => {
            if (field.groupBy && existingField.groupBy) {
                // If both fields have groupBy, compare both the name and groupBy properties
                return existingField.name === fieldName && existingField.groupBy === field.groupBy;
            }
            // If neither or only one field has groupBy, compare just the name
            return existingField.name === fieldName;
        });

        if (existingFieldIndex !== -1) {
            fields[existingFieldIndex] = field;
        } else {
            // If no existing field is found, add the new field
            fields.push(field);
        }
    }

    const updateField = (fieldName, groupByValue, updatedProps) => {
        if (debug) {
            console.log('updateField', name, 'name', fieldName, 'groupByValue', groupByValue, 'updatedProps', updatedProps);
        }

        const fieldIndex = fields.findIndex(field => field.name === fieldName && field.groupBy === groupByValue);
        if (fieldIndex !== -1) {
            const existingField = fields[fieldIndex];

            // Check if the properties have actually changed
            if (Object.keys(updatedProps).some(key => existingField[key] !== updatedProps[key])) {
                fields[fieldIndex] = { ...existingField, ...updatedProps };
            }
        }
    };

    const removeField = (fieldName, groupBy) => {
        if (debug) {
            console.log('removeField', name, 'name', fieldName);
        }

        // Find and remove the field from the fields array
        for (let i = 0; i < fields.length; i++) {
            if ((groupBy && fields[i].groupBy === groupBy && fields[i].name === fieldName) ||
                (!groupBy && fields[i].name === fieldName)) {
                fields.splice(i, 1);
                break; // Exit the loop after removing the item
            }
        }

        // Directly modifying the form object
        if (groupBy) {
            // If groupBy is present, update the array inside the group
            if (form[groupBy]) {
                form[groupBy] = form[groupBy].filter(item => item.name !== fieldName);
            }
        } else {
            // If there's no groupBy, remove the field directly
            delete form[fieldName];
        }
    };

    const onDirty = (form) => {
        let currentForm = _.clone(form);

        // Remove unbound fields
        for (const fieldKey of Object.keys(fields)) {
            const field = fields[fieldKey];
            if (field.unbound) {
                currentForm[field.name] = null;
                delete currentForm[field.name];
            }
        }

        let saveMode = false;

        // Check if any values have been set
        for (const field in currentForm) {
            const value = currentForm[field];
            if (Array.isArray(value) && value.length > 0) {
                saveMode = true;
            } else if (typeof value === "object" && Object.keys(value).length > 0) {
                saveMode = true;
            } else if (value !== undefined && value !== null && value !== '') {
                saveMode = true;
            }

            // Exit loop as soon as a dirty value is found
            if (saveMode) break;
        }

        if (typeof dirtyHandler === "function") {
            dirtyHandler(saveMode);
        }
    }

    return (
        <PlatformFormContext.Provider
            value={{
                addField,
                removeField,
                updateField,
                resetForm,
                addChangeHandler: (callback) => {
                    changeCallback = callback;
                },
                setFormValue: (name, value, groupBy) => {
                    formRef?.current?.setValue(name, value, groupBy);
                },
                getFormValue: (name, groupBy) => {
                    return formRef?.current?.getValue(name, groupBy);
                },
                getFormValueDTO: (name, groupBy) => {
                    return formRef?.current?.getGroupValueAsObject(name, groupBy);
                },
                getFormGroupValue: (groupBy) => {
                    return formRef?.current?.getGroupValue(groupBy);
                },
                validateForm: () => {
                    return formRef?.current?.validate();
                },
                getForm: () => {
                    return formRef?.current?.getForm();
                },
                setForm: (form) => {
                    setTimeout(() => {
                        formRef?.current?.setForm(form);
                    }, 100)
                },
                form: formRef
            }}
        >
            <>{children}</>
        </PlatformFormContext.Provider>
    );
}