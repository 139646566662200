import "assets/scss/material-kit-react.scss?v=1.10.0";
import React from "react";
import ReactGA from 'react-ga4';
import {createBrowserHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";
import CheckoutCartHome from "./views/cart/cartHome.js";
import CartHome from "./views/cart/cartHome.js";
import SignupHome from "./views/public/signup/signupHome";
import LoginHome from "./views/public/login/loginHome";
import ScrollAuto from "./components/shared/util/scrollAuto";
import {createRoot} from 'react-dom/client';
import FavoritesHome from "./views/favorites/favoritesHome";
import PasswordHome from "./views/public/password/passwordHome";
import OAuth2RedirectHome from "./views/oauth2/oAuth2RedirectHome";
import {ThemeProvider} from '@mui/material/styles';
import theme from './theme';
import CatalogIndex from "./views/catalog/catalogIndex";
import {AppContextProvider} from "./appContext";
import CheckoutIndex from "./views/checkout/checkoutIndex";
import HomeIndex from "./views/home/homeIndex";
import CatalogItemIndex from "./views/catalog/item/catalogItemIndex";
import OrderReceiptIndex from "./views/order/receipt/orderReceiptIndex";
import OrderStatusIndex from "./views/order/status/orderStatusIndex";
import LocatorIndex from "./views/store/locator/locatorIndex";
import TermsOfServiceIndex from "./views/public/termsofservice/termsOfServiceIndex";
import PrivacyPolicyIndex from "./views/public/privacypolicy/privacyPolicyIndex";
import RefundPolicyIndex from "./views/public/refundpolicy/refundPolicyIndex";
import BlogOurProcessIndex from "./views/blog/ourProcess/blogOurProcessIndex";
import BlogWhatIsThcaIndex from "./views/blog/whatIsThca/blogWhatIsThcaIndex";
import BlogHowIsThisLegal from "./views/blog/howIsThisLegal/blogHowIsThisLegal";
import BlogOurCannabinoids from "./views/blog/ourCannabinoids/blogOurCannabinoids";
import BlogWhatMakesGreenbeltQuality from "./views/blog/highQuality/blogWhatMakesGreenbeltQuality";
import AboutUsIndex from "./views/home/aboutUs/aboutUsIndex";
import ContactIndex from "./views/home/contact/contactIndex";
import CatalogShopAllIndex from "./views/catalog/all/catalogShopAllIndex";
import FdaDisclaimerIndex from "./views/public/fdadisclaimer/fdaDisclaimerIndex";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
let browserHistory = createBrowserHistory();
ReactGA.initialize('G-N78XWCD97Y');

browserHistory.listen((location) => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
});

root.render(
    <ThemeProvider theme={theme}>
        <AppContextProvider>
                <Router history={browserHistory}>
                    <ScrollAuto>
                        <Switch>
                            <Route path="/sso/redirect" component={OAuth2RedirectHome}/>
                            <Route path="/store/:slug/sign-in" component={LoginHome}/>
                            <Route path="/signin" exact component={LoginHome} />
                            <Route path="/signup" exact component={SignupHome} />
                            <Route path="/cart" exact component={CartHome} />
                            <Route path="/catalog" exact component={CatalogShopAllIndex}/>
                            <Route path="/catalog/:category" exact component={CatalogIndex}/>
                            <Route path="/catalog/:category/product/:productId/variant/:variantId/:variantSlug"
                                   exact component={CatalogItemIndex}/>
                            <Route path="/checkout" exact component={CheckoutIndex} />
                            <Route path="/checkout/cart" exact component={CheckoutCartHome} />
                            <Route path="/order/receipt" exact component={OrderReceiptIndex} />
                            <Route path="/order/status" exact component={OrderStatusIndex} />
                            <Route path="/store/locator" exact component={LocatorIndex} />
                            <Route path="/favorites" exact component={FavoritesHome} />
                            <Route path="/password/reset" exact component={PasswordHome} />
                            <Route path="/password/reset/:token" exact component={PasswordHome} />
                            <Route path="/terms-of-service" exact component={TermsOfServiceIndex} />
                            <Route path="/fda-disclaimer" exact component={FdaDisclaimerIndex} />
                            <Route path="/privacy-policy" exact component={PrivacyPolicyIndex} />
                            <Route path="/about-us" exact component={AboutUsIndex} />
                            <Route path="/contact" exact component={ContactIndex} />
                            <Route path="/refund-and-returns" exact component={RefundPolicyIndex} />
                            <Route path="/blog/our-process" exact component={BlogOurProcessIndex} />
                            <Route path="/blog/what-is-thca" exact component={BlogWhatIsThcaIndex} />
                            <Route path="/blog/how-is-this-legal" exact component={BlogHowIsThisLegal} />
                            <Route path="/blog/our-cannabinoids" exact component={BlogOurCannabinoids} />
                            <Route path="/blog/high-quality" exact component={BlogWhatMakesGreenbeltQuality} />
                            <Route path="/" component={HomeIndex} />
                        </Switch>
                    </ScrollAuto>
                </Router>
        </AppContextProvider>
    </ThemeProvider>
);