import React, {useState} from 'react';
import {Box, Container, Grid, Skeleton, Typography} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import {isMobile} from "react-device-detect";
import CircleIcon from '@mui/icons-material/Circle';
import GridTile from "../gridTile/gridTile";
import PlatformSpace from "../../../../components/platform/platformSpace";
import {styled} from "@mui/styles";

// Helper function to create chunks of items
const createChunks = (items, chunkSize) => {
    let chunks = [];
    for (let i = 0; i < items.length; i += chunkSize) {
        chunks.push(items.slice(i, i + chunkSize));
    }
    return chunks;
};

const StyledSwipeableViews = styled(SwipeableViews)({
    '& .react-swipeable-view-container': {
        display: 'flex',
    },
    '& .react-swipeable-view-container > div': {
        flex: '1 0 100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
});

export default function GridTilePager({
                                          title,
                                          products,
                                          headless,
                                          chunkSize = 4,
                                          titleJustification = 'center',
                                          mode = 'swipeable',
                                          isLoading = false
                                      }) {

    const itemChunks = createChunks(products, chunkSize);
    const [activeStep, setActiveStep] = useState(0);
    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const renderGrid = (chunks, isSkeleton = false) => (
      <Grid container justifyContent={titleJustification} alignItems={titleJustification}
            rowSpacing={isMobile ? 0 : 2} spacing={isMobile ? 0 : 1} sx={{margin: isMobile ? 0 : "10px 0 25px 0"}}
      >
          {chunks.map((chunk, idx) => (
            isSkeleton ?
              <Grid item key={idx} md={isMobile ? 6 : Math.floor(12 / chunkSize)} xs={6}>
                  <Skeleton variant="rectangular" height={118} animation="wave"/>
              </Grid>
              :
              chunk.map((item, index) => (
                <Grid sx={{padding: 1}} item key={`${item.variant.variantId}-${index}`} md={isMobile ? 6 : Math.floor(12 / chunkSize)} xs={6}>
                    <GridTile product={item} mode={mode}/>
                </Grid>
              ))
          ))}
      </Grid>
    );

    return (
      <Container maxWidth="lg">
          {!headless && (
            <>
                <Typography variant={isMobile ? "h4" : "h3"} gutterBottom align={titleJustification}
                            sx={{marginLeft: titleJustification === "center" ? 0 : 2}}>
                    {isLoading ? <Skeleton width="40%" height={28}/> : title}
                </Typography>
                <PlatformSpace height={10}/>
            </>
          )}
          {mode === 'swipeable' ? (
            <StyledSwipeableViews
              axis={'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              //containerStyle={{paddingLeft: 16, paddingRight: 16}}
            >
                {isLoading ? renderGrid(new Array(chunkSize).fill([]), true) : itemChunks.map((chunk, chunkIndex) => (
                  renderGrid([chunk])
                ))}
            </StyledSwipeableViews>
          ) : (
            isLoading ? renderGrid(new Array(chunkSize).fill([]), true) : renderGrid(itemChunks)
          )}
          {mode === 'swipeable' && !isLoading && (
            <Box display="flex" justifyContent={titleJustification} mt={2}>
                {itemChunks.map((_, index) => (
                  <Box
                    key={index}
                    mx={1}
                    component="button"
                    onClick={() => handleStepChange(index)}
                    style={{background: 'none', border: 'none', padding: 0}}
                  >
                      <CircleIcon style={{cursor: "pointer", opacity: activeStep === index ? 1 : 0.4}}/>
                  </Box>
                ))}
            </Box>
          )}
      </Container>
    );
}
