import * as React from 'react';
import {useContext, useState} from 'react';
import useLayoutOptions from "../../components/hook/useLayoutOptions";
import PublicHeader from "../../components/layout/publicHeader";
import PublicFooter from "../../components/layout/publicFooter";
import HomeBackgroundVideo from "./homeBackgroundVideo";
import HomeFAQ from "./homeFAQ";
import HomeLearnAboutGreenbelt from "./homeLearnAboutGreenbelt";
import HomeAboutUs from "./homeAboutUs";
import HomeWhatWeDo from "./homeWhatWeDo";
import HomeBestSellers from "./homeBestSellers";
import HomeTrendingNow from "./homeTrendingNow";
import HomeAsSeenIn from "./homeAsSeenIn";
import HomeEthos from "./homeEthos";
import HomeSpacer from "./homeSpacer";
import HomeFeature2 from "./homeFeature2";
import FavoriteVariantIndex from "../catalog/favorite/favoriteVariantIndex";
import HomeProductFeatures from "./homeProductFeatures";
import {isMobile} from "react-device-detect";
import PublicContext from "../../components/layout/publicContext";
import HomeProductFeature from "./homeProductFeature";

export default function HomeHome(props) {
    const { showPromoBanner } = useContext(PublicContext);

    const [slide, setSlide] = useState(0);
    //const {onLayoutOptions} = useContext(AppContext);
    const isBeta = Boolean(localStorage.getItem("beta"));

    useLayoutOptions({
        backgroundComponent: HomeBackgroundVideo,
        headerComponent: PublicHeader,
        footerComponent: PublicFooter,
        video: SLIDES[slide].video,
        blur: "10px",
        //topMargin: !showPromoBanner && isMobile ? 70 : 0
        topMargin: isMobile ? 70 : 0
    });

    // const onChange = (slide) => {
    //     setSlide(slide);
    //     onLayoutOptions({
    //         backgroundComponent: HomeBackgroundVideo,
    //         video: SLIDES[slide].video,
    //         backgroundOpacity: 0.2,
    //         blur: "10px",
    //         topMargin: 70
    //     });
    // }

    return (
        <React.Fragment>
            <HomeSpacer/>
            {/*{!isBeta && <HomeBeta/>}*/}
            <HomeFeature2/>
            <HomeSpacer/>
            <FavoriteVariantIndex showButton={true}/>
            <HomeSpacer/>
            <HomeAsSeenIn/>
            <HomeSpacer/>
            <HomeWhatWeDo/>
            <HomeSpacer/>
            <HomeBestSellers/>
            <HomeSpacer/>
            <HomeProductFeature
                title="Farm to Shop"
                description="Quality starts with the finest ingredients. Discover how we bring our products from a small, locally-owned farm directly to your doorstep by delving into our meticulous process."
                buttonText="OUR PROCESS"
                imageUrl={`${process.env.PUBLIC_URL}/images/hh/OurProcess.jpg`}
                imagePosition="right" // can be 'left' or 'right'
                productLink="/blog/our-process"
            />
            <HomeSpacer/>
            <HomeTrendingNow/>
            <HomeSpacer/>
            <HomeEthos/>
            <HomeSpacer/>
            <HomeProductFeatures/>
            <HomeSpacer/>
            <HomeAboutUs/>
            <HomeSpacer/>
            <FavoriteVariantIndex showButton={true}/>
            <HomeSpacer/>
            <HomeLearnAboutGreenbelt/>
            <HomeSpacer/>
            <HomeFAQ/>
            <HomeSpacer/>
            {/*<HomeBenefitsBar/>*/}
            {/*<HomeUglyCategories/>*/}
        </React.Fragment>
    )
}

const SLIDES = [
    {
        title: "Wedding Cake",
        image: "slides/thca_kushmintz.png",
        link: "catalog/flower?Brand=THCA%20Flower"
    },
    {
        title: "Wedding Cake",
        image: "slides/thca_kushmintz.png",
        link: "catalog/flower?Brand=THCA%20Flower"
    }
]