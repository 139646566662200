import React from 'react';
import {Box, Typography} from '@mui/material';
import PlatformMarginBox from "../../../components/platform/platformMarginBox";
import PlatformSpace from "../../../components/platform/platformSpace";

export default function CatalogItemEffects({title, image, mode = 'cover'}) {
    return (
        <PlatformMarginBox>
            <Box flex={1}>
                <Typography variant="h3" component="h1" gutterBottom>
                    Embrace Your Authentic Self
                </Typography>
                <Typography variant="h8" gutterBottom>
                    That familiar, comforting feeling. You don't need anything fancy; you just want to feel like yourself.
                </Typography>
                <PlatformSpace height={30}/>
                <Typography variant="h8">
                    Perfect for:
                </Typography>
                <PlatformSpace height={10}/>
                <Typography variant="h8">
                    ✦ Seasoned adventurers
                </Typography>
                <PlatformSpace height={10}/>
                <Typography variant="h8">
                    ✦ First-time explorers
                </Typography>
                <PlatformSpace height={10}/>
                <Typography variant="h8">
                    ✦ Anyone seeking new experiences
                </Typography>
            </Box>
            <Box flex={1} position="relative">
                <img
                    src={`${process.env.PUBLIC_URL}/images/hh/CannabisEffectsPerson.jpg`}
                    alt="Cannabis leaves"
                    style={{width: '100%', height: 'auto', borderRadius: '20px'}}
                />
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    //bgcolor="rgba(0, 255, 0, 0.5)"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color="white"
                    px={2}
                    sx={{borderRadius: '20px'}}
                >
                    <Typography variant="h5" sx={{color: "white"}}>
                        Uncover New Possibilities
                    </Typography>
                </Box>
            </Box>
        </PlatformMarginBox>
    );
};