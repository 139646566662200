import * as React from 'react';
import {useState} from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {styled} from "@mui/styles";


export default function PlatformSliderButtons({tabs, changeHandler}) {
    const [value, setValue] = useState("0");

    const onTabChange = (event, newValue) => {
        setValue(newValue);
        if (typeof changeHandler === "function") {
            changeHandler(tabs[newValue], newValue);
        }
    };

    return (
      <div>
          <TabContext value={value}>
              <TabButtons
                onChange={onTabChange}
                aria-label="Top charts"
                centered
                variant="standard"
                TabIndicatorProps={{
                    sx: {
                        borderRadius: "20px",
                        boxShadow: "inset 0 0 0 4px rgba(9,45,33,1)",
                        background: "rgba(255,255,255,0.7)",
                        height: "100%",
                        opacity: 0.5
                    }}}
              >
                  {tabs.map((tab, idx) => (
                    <TabButton key={`${tab.label}-${idx}`} label={tab.label} value={String(idx)} isFirst={idx === 0}
                               isLast={idx === tabs.length - 1}/>
                  ))}
              </TabButtons>
          </TabContext>
      </div>
    );
}

const TabButtons = styled(TabList)({
    backgroundColor: "#f7f6f2", // Light gray background
    borderRadius: 20,
    width: "100%", // Use the full width of the screen
});

const TabButton = styled(Tab)(({isFirst, isLast}) => ({
    '&.MuiTab-root': {
        fontSize: "1.2rem !important",
        color: "white",
        minWidth: 120,
        backgroundColor: "rgba(9,45,33,1)",
        border: "none !important",
        '&:hover': { // Hover state for the tab
            color: "rgba(178,172,172,0.51) !important", // Keep the color white on hover
        }
    },
    '&.MuiTab-root.Mui-selected': {
        color: "white !important",
        borderRadius: 0,
        //rgba(178,172,172,0.51)
        opacity: 1, // Ensure full opacity
        backgroundColor: "rgba(9,45,33,1)", // Ensure background remains consistent
    },
    ...(isFirst && {
        borderRadius: "20px 0 0 20px !important",
    }),
    ...(isLast && {
        borderRadius: "0 20px 20px 0 !important",
    }),
}));