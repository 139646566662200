import React from 'react';
import HomeProductFeature from "./homeProductFeature";

export default function HomeProductFeatures() {
    return (
        <React.Fragment>
            <HomeProductFeature
              title="Cann by Social Tonic"
              description="Elevate your social experience with Cann Social Tonic, a refreshing blend of natural flavors and low-dose cannabis that offers a blissful buzz without the hangover. Perfect for any occasion, Cann Social Tonic delivers a delightful and uplifting alternative to traditional beverages."
              buttonText="EXPLORE CANN"
              imageUrl={`${process.env.PUBLIC_URL}/images/featured/Cann.jpg`}
              imagePosition="left" // can be 'left' or 'right'
              productLink="/catalog/beverages?Brand=Social%20Tonic"
            />
            <HomeProductFeature
              title="Wyld CBD Gummies"
              description="Experience the deliciously fruity and soothing effects of Wyld CBD Gummies, crafted with real fruit and broad-spectrum hemp extract to provide a natural, balanced boost to your wellness routine. Perfect for those seeking a tasty and convenient way to enjoy the benefits of CBD, Wyld CBD Gummies are your go-to treat for relaxation and relief."
              buttonText="EXPLORE WYLD"
              imageUrl={`${process.env.PUBLIC_URL}/images/featured/Wyld.jpg`}
              imagePosition="right" // can be 'left' or 'right'
              productLink="/catalog/gummies?Brand=Wyld%20Gummies"
            />
        </React.Fragment>
    );
}
