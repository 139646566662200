import React, {useContext} from 'react';
import {Box, Container, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {styled} from '@mui/material/styles';
import {isMobile} from 'react-device-detect';
import PublicContext from './publicContext';
import AppContext from '../../appContext';

export default function PublicPromoBanner({ children }) {
    const { onClosePromoBanner, showPromoBanner } = useContext(PublicContext);
    const { layoutOptions } = useContext(AppContext);

    // Outer container for the full-width background
    const FullWidthBox = styled(Box)(({ theme }) => ({
        backgroundColor: 'rgba(142,243,142,0.4)',
        padding: theme.spacing(1),
        marginTop: isMobile && layoutOptions.headerComponent ? 135 : theme.spacing(2),
        marginBottom: isMobile ? 0 : 50,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }));

    // Centering content within the max-width of 'lg'
    const StyledBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        maxWidth: '100%',
    }));

    const CenteredContainer = styled(Container)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
    }));

    return (
        <React.Fragment>
            {(showPromoBanner && 1 === 2) ? (
                <FullWidthBox>
                    <CenteredContainer maxWidth="lg" sx={{ margin: 1 }}>
                        <StyledBox>
                            <Typography variant="body2" component="h2">
                                MEMORIAL DAY SALE
                            </Typography>
                            <Typography variant="h5" component="h2">
                                15% OFF all products in stock
                            </Typography>
                        </StyledBox>
                    </CenteredContainer>
                    <IconButton onClick={onClosePromoBanner} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </FullWidthBox>
            ) : null}
            {children}
        </React.Fragment>
    );
}
