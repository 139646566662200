import React from 'react';
import {Box, Typography} from '@mui/material';
import PublicLayout from "../../../components/layout/publicLayout";
import {isMobile} from "react-device-detect";
import PlatformSpace from "../../../components/platform/platformSpace";
import PlatformMarginBox from "../../../components/platform/platformMarginBox";
import BlogWithMoodBanner from "../../../components/shared/blog/blogWithMoodBanner";

export default function BlogHowIsThisLegal() {
    return (
        <PublicLayout>
            <BlogWithMoodBanner
                title="Understanding the Legal Landscape of Cannabis"
                image={`${process.env.PUBLIC_URL}/images/hh/IsGreenbeltLegal.jpg`}
            />
            <PlatformSpace height={isMobile ? 30 : 60}/>
            <PlatformMarginBox>
                <Box flex={1}>
                    <Typography variant="h5" gutterBottom>
                        The allure of cannabis-based products for health and wellness has dramatically increased in recent times.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="body1" gutterBottom>
                        As consumer preferences shift, so does the legality of different cannabis derivatives. This article will clarify the legal complexities of cannabis and illustrate why products with certain cannabinoids are lawful federally and can be dispatched to numerous states across the United States.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        The Cannabis Components Utilized by Greenbelt
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Greenbelt primarily uses hemp-sourced THCa in our flowers and vape products, a non-intoxicating component in its unprocessed form. It is only when THCa undergoes heating, as in smoking, that it transforms into the psychoactive THC, through decarboxylation, leading to the 'high' effect.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Our array of edibles and non-heated concentrates typically incorporate hemp-based Delta-8 and Delta-9 THC. While both are psychoactive, they differ from THCa in structure, thus offering distinctive effects and strengths.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        The Legality of THCa and Other Cannabinoids
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Indeed, the 2018 Farm Bill paves the legal pathway for hemp cultivation and sale of derivatives like THCa, Delta-8, and Delta-9 THC, defining hemp as cannabis variants containing no more than 0.3% Delta-9 THC on a dry weight basis, separate from marijuana's classification.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Compliance with the Farm Bill allows for the distribution of these products across most US states. Stringent regulations govern hemp cultivation and mandatory product testing to ensure compliance, as upheld by Greenbelt.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        The Origins of Greenbelt's Cannabis
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        We source all our cannabis from American hemp farms known for their pesticide-free approach and decades of expertise. Our farmers employ proprietary strains cultivated over years, delivering cannabis that's noticeably superior to the mass-produced alternatives. Learn more about how we uphold quality and safety.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        Discrepancies Between Federal and State Cannabis Laws
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Although federally legal, state legislation on hemp-derived products is inconsistent. Some states are more lenient, while others have stringent measures or prohibitions against certain cannabis products.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Understanding the Legality of Transporting Cannabis in the US
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Traveling with cannabis across states can be lawful, provided the products are derived from hemp with 0.3% or less Delta-9 THC by dry weight and meet all pertinent regulations. However, carrying THC-heavy products exceeding this threshold is a federal offense, regardless of state laws, barring a few exceptions for medically-approved cannabis.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Given the fluid nature of cannabis laws, Greenbelt is vigilant in adhering to current regulations and ensures our offerings are not just of high quality but also legally compliant across the federation.
                    </Typography>
                </Box>
            </PlatformMarginBox>
            <PlatformSpace height={isMobile ? 30 : 60}/>
        </PublicLayout>
    );
};
