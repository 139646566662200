import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import {styled, useTheme} from '@mui/material/styles';
import {Link} from "@mui/material";
import {isDesktop, isMobile} from "react-device-detect";
import SwipeableViews from "react-swipeable-views";
import CircleIcon from "@mui/icons-material/Circle";

const ItemPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    backgroundColor: 'transparent',
}));

const ImageBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    '&:after': {
        content: '" "',
        display: 'block',
        paddingBottom: 'calc(100% / (16 / 9))',
    },
    '& img': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        top: 0,
        left: 0,
    },
}));

const LearnMoreButton = styled(Link)(({ theme }) => ({
    marginTop: theme.spacing(1),
    padding: 0,
    textTransform: 'none',
    fontWeight: 800,
    fontSize: '1rem',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    color: theme.palette.primary.main,
}));

export default function HomeLearnAboutGreenbelt() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = 3;

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const paperContent = [
        {
            title: "What is THCa?",
            description: "Discover everything you need to know about this crucial compound and its benefits.",
            url: "/blog/what-is-thca",
        },
        {
            title: "What Makes Greenbelt So High Quality?",
            description: "Explore why Greenbelt's federally legal THC stands out from the competition.",
            url: "/blog/high-quality",
        },
        {
            title: "What are cannabinoids?",
            description: "Dive into the intriguing world of cannabis compounds and their effects.",
            url: "/blog/our-cannabinoids",
        }
    ];

    return (
        <Container maxWidth="lg" sx={{ overflowX: 'hidden' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    gap: 2,
                    width: '100%',
                    maxWidth: '100%',
                    overflowX: 'hidden',
                }}
            >
                <ImageBox sx={{ flex: 3, maxWidth: '100%' }}>
                    <img
                        src={`${process.env.PUBLIC_URL}/images/hh/LearnAboutGreenbelt.jpg`}
                        alt="Person using cannabis"
                        style={{ borderRadius: '20px' }}
                    />
                    <Typography
                        variant="h5"
                        sx={{ position: 'absolute', top: 16, left: 16, color: 'white' }}
                    >
                        Learn about Greenbelt
                    </Typography>
                </ImageBox>

                <Box
                    sx={{
                        flex: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        maxWidth: '100%',
                    }}
                >
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                        style={{ width: "100%", display: isMobile ? 'block' : 'none' }}
                    >
                        {paperContent.map((content, index) => (
                            <ItemPaper key={content.title}>
                                <Typography variant="h6">{content.title}</Typography>
                                <Typography>{content.description}</Typography>
                                <LearnMoreButton component="a" href={content.url}>
                                    Learn More →
                                </LearnMoreButton>
                            </ItemPaper>
                        ))}
                    </SwipeableViews>
                    {isDesktop &&
                        <React.Fragment>
                            {paperContent.map((content, index) => (
                                <ItemPaper
                                    key={content.title}
                                    sx={{ display: { xs: activeStep === index ? 'block' : 'none', md: 'block' } }}
                                >
                                    <Typography variant="h6">{content.title}</Typography>
                                    <Typography>{content.description}</Typography>
                                    <LearnMoreButton component="a" href={content.url}>
                                        Learn More →
                                    </LearnMoreButton>
                                </ItemPaper>
                            ))}
                        </React.Fragment>
                    }
                </Box>
            </Box>
            {isMobile && (
                <Box display="flex" justifyContent="center" mt={2}>
                    {paperContent.map((_, index) => (
                        <Box
                            key={index}
                            mx={1}
                            component="button"
                            onClick={() => handleStepChange(index)}
                            style={{ background: 'none', border: 'none', padding: 0 }}
                        >
                            <CircleIcon style={{ opacity: activeStep === index ? 1 : 0.4 }} />
                        </Box>
                    ))}
                </Box>
            )}
        </Container>
    );
}
