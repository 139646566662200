import React, {useRef, useState} from 'react';
import {makeStyles} from '@mui/styles';
import Typography from '@mui/material/Typography';
import styles from "./loginStyles";
import {useHistory, useParams} from "react-router-dom";
import PlatformButton from "components/platform/platformButton";
import PlatformSpace from "components/platform/platformSpace";
import {Box, Container, Grid} from "@mui/material";
import PlatformTextField from "components/platform/platformTextField";
import PlatformForm from "components/platform/platformForm";
import LoginApi from "./loginApi";
import {Alert} from "@mui/lab";
import PublicApi from "../../../components/layout/_attic/publicApi";
import {storeObject, storeString} from "components/shared/util/storageUtil";
import PlatformLink from "../../../components/platform/platformLink";

const useStyles = makeStyles(styles);

export const GOOGLE_AUTH_URL = (process.env.REACT_APP_HOST || "localhost:5001") + '/oauth2/authorize/google';
export const FACEBOOK_AUTH_URL = (process.env.REACT_APP_HOST || "localhost:5001") + '/oauth2/authorize/facebook';

export default function LoginForm(props) {
    const classes = useStyles();
    const history = useHistory();
    const formRef = useRef(null);
    let { slug } = useParams();
    const [error, setError] = useState(null);

    const onSignIn = () => {
        let isValid = formRef.current.validate();
        if (isValid) {
            LoginApi.signIn({
                username: formRef.current.getValue("email"),
                password: formRef.current.getValue("password"),
                appId: "client"
            }, (response) => {
                storeString("token", response.accessToken);

                let getUserPromise = onGetUser();
                //let getCustomerFeatures = onGetCustomerFeatures();
                Promise.allSettled([getUserPromise]).then((results) => {
                    history.push({
                        pathname: "/"
                    })
                })
            }, (error) => {
                localStorage.setItem("token", null);
                //setError(error.data);
            })
        }
    }

    const onGetUser = () => {
        return new Promise(function(resolve, reject) {
            PublicApi.getUser((user) => {
                storeObject("user", user);
                resolve(true);
            })
        })
    }

    const onGetCustomerFeatures = () => {
        return new Promise(function(resolve, reject) {
            PublicApi.getCustomerFeatures((features) => {
                storeObject("features", features);
                resolve(true);
            })
        })
    }

    const onForgotPassword = () => {
        history.push({
            pathname: "/password/reset"
        })
    }

    return (
        <div style={{height: "100vh"}}>
            {error && <Alert severity="error">{error}</Alert>}
            <PlatformSpace height={20}/>
            <Grid container>
                <Grid item container justifyContent="center" xs={12}>
                    <Box display="flex"
                         justifyContent="center"
                         alignItems="center">
                        <Typography variant="h4" style={{textTransform: "none", color: "black"}}>
                            Sign in
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <PlatformSpace height={40}/>
            <Container maxWidth="xs" style={{backgroundColor: "white", borderRadius: 5, padding: 20}}>
                <PlatformForm ref={formRef}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <PlatformTextField
                                defaultValue=""
                                required
                                email
                                fullWidth
                                placeholder="Email"
                                name="email"
                                label="Email"
                                errorText="Email is required"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PlatformTextField
                                defaultValue=""
                                required
                                fullWidth
                                type="password"
                                placeholder="Password"
                                name="password"
                                label="Password"
                                errorText="Password is required"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PlatformLink inverse onClick={onForgotPassword} >Forgot your password?</PlatformLink>
                        </Grid>
                        <Grid item xs={12}>
                            <PlatformButton onClick={onSignIn} color="inherit" size="lg" fullWidth>Sign In</PlatformButton>
                        </Grid>
                    </Grid>
                    <Grid xs={12}>
                        <div className="social-login">
                            <div style={{padding: 10}}>
                                <a href={`${GOOGLE_AUTH_URL}?slug=${slug}`}>
                                    <img style={{height: 24, marginTop: 7}} src={`${process.env.PUBLIC_URL}/images/social/google-logo.png`} alt="Google" />
                                    <span>Log in with Google</span>
                                </a>
                            </div>
                            <div>
                                <a href={FACEBOOK_AUTH_URL}>
                                    <img style={{height: 24, marginLeft: 3}} src={`${process.env.PUBLIC_URL}/images/social/fb-logo.png`} alt="Facebook" />
                                    <span>Log in with Facebook</span>
                                </a>
                            </div>
                        </div>
                    </Grid>
                </PlatformForm>
            </Container>
        </div>
    );
}