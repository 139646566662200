import React from 'react';
import {Box, Card, Grid, Typography, useMediaQuery, useTheme} from '@mui/material';
import {styled} from '@mui/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import {isMobile} from "react-device-detect";
import {Park} from "@mui/icons-material";
import PlatformSpace from "../../../components/platform/platformSpace";

const StyledSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const TextSection = styled(Box)(({ theme }) => ({
    maxWidth: '45%',
    [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        textAlign: 'center',
    },
}));

const ImageSection = styled('img')({
    maxWidth: '100%',
    height: 'auto',
    margin: '16px 0',
    borderRadius: 12,
});

const StepsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
    },
}));

const StepCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
    padding: isMobile ? "0 0 30px 0" : theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
}));

const StyledIcon = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '64px',
    minHeight: '64px',
    borderRadius: '50%',
    background: 'linear-gradient(135deg, #f0f0f0, #e0e0e0)',
    marginRight: '1rem',
    '& .MuiSvgIcon-root': {
        fontSize: '2rem',
    },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
    marginBottom: '0.5rem',
}));

const StyledDescription = styled(Typography)(({ theme }) => ({}));

const steps = [
    {
        icon: <CheckCircleOutlineIcon />,
        title: 'Premium Buds',
        description: 'Our top-quality buds are sent to Oklahoma for transformation into premium flower products.',
    },
    {
        icon: <ContentCutIcon />,
        title: 'Small Buds & Trimmings',
        description: 'The smaller parts undergo supercritical CO2 extraction to harness their natural goodness.',
    },
    {
        icon: <Park />,
        title: 'Plant Stems',
        description: 'We recycle stems into compost for fertilizing or dispose of them sustainably.',
    },
];

export default function BlogOurProcessTrimmingAndProcessing() {
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ backgroundColor: '#f3efee', padding: isMobile ? 0 : 4 }}>
            <Box sx={{ px: isMobile ? 1 : 20, maxWidth: isMobile ? "100vw" : '90%', mx: isMobile ? 1 : 'auto' }}>
                <Typography variant="h5" gutterBottom>
                    Trimming & Processing
                </Typography>
                <Typography variant="body1">
                    Growing quality hemp requires time, patience, and effort, and we ensure nothing goes to waste.
                </Typography>
                {isMobile &&
                    <PlatformSpace height={20} />
                }
                <StyledSection>
                    <TextSection>
                        {!isMobileView && (
                            <ImageSection src={`${process.env.PUBLIC_URL}/images/hh/OurProcessTrimNProcess.jpg`} alt="Trimming" />
                        )}
                    </TextSection>
                    <StepsContainer container>
                        {steps.map((step, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                <StepCard>
                                    <StyledIcon>{step.icon}</StyledIcon>
                                    <Box>
                                        <StyledTitle variant="h6">{step.title}</StyledTitle>
                                        <StyledDescription variant="body2">{step.description}</StyledDescription>
                                    </Box>
                                </StepCard>
                            </Grid>
                        ))}
                    </StepsContainer>
                </StyledSection>
            </Box>
        </Box>
    );
};
