import React, {useContext} from 'react';
import {Box, Link, Typography, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import PublicLayout from "../../../components/layout/publicLayout";
import PlatformSpace from "../../../components/platform/platformSpace";
import HomeFAQ from "../homeFAQ";
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';
import {styled} from "@mui/styles";
import BlogWithFullMoodBanner from "../../../components/shared/blog/blogWithFullMoodBanner";
import PlatformMarginBox from "../../../components/platform/platformMarginBox";
import {ORGANIZATION} from "../../../components/constants/organization";
import PublicContext from "../../../components/layout/publicContext";

const StyledIcon = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '64px',
    minHeight: '64px',
    borderRadius: '50%',
    background: 'linear-gradient(135deg, #f0f0f0, #e0e0e0)',
    marginRight: '1rem',
    '& .MuiSvgIcon-root': {
        fontSize: '2rem',
    },
}));

export default function ContactHome() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const {onContact} = useContext(PublicContext);

    return (
      <React.Fragment>
          <BlogWithFullMoodBanner
            title="Our cannabis experts are standing by."
            subtitle="Contact"
            image={`${process.env.PUBLIC_URL}/images/hh/ContactUs.jpg`}
          />
          <PlatformSpace height={isMobile ? 30 : 60} />
          <PlatformMarginBox>
              <Box flex={1}>
                  <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: isMobile ? 'flex-start' : 'center',
                        backgroundColor: '#f5f5f5',
                        padding: '16px',
                    }}
                  >
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: isMobile ? 2 : 0, height: '10vh', flexGrow: 1 }}>
                          <StyledIcon>
                              <CallIcon />
                          </StyledIcon>
                          <Box sx={{ flexGrow: 1 }}>
                              <Typography variant="h6">
                                  CALL US TOLL FREE
                              </Typography>
                              <Typography variant="body1">
                                  {ORGANIZATION.supportHours}<br />
                                  {ORGANIZATION.supportPhone}
                              </Typography>
                          </Box>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: isMobile ? 2 : 0, height: '10vh', flexGrow: 1 }}>
                          <StyledIcon>
                              <EmailIcon />
                          </StyledIcon>
                          <Box sx={{ flexGrow: 1 }}>
                              <Typography variant="h6">
                                  EMAIL
                              </Typography>
                              <Link href={`mailto:${ORGANIZATION.supportEmail}`} color="inherit" underline="none">
                                  {ORGANIZATION.supportEmail}
                              </Link>
                          </Box>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', height: '10vh', flexGrow: 1 }}>
                          <StyledIcon>
                              <ChatIcon />
                          </StyledIcon>
                          <Box sx={{ flexGrow: 1 }}>
                              <Typography variant="h6">
                                  SEND A MESSAGE
                              </Typography>
                              <Link href="#" color="inherit" underline="none" onClick={onContact}>
                                  Click here
                              </Link>
                          </Box>
                      </Box>
                  </Box>
                  <PlatformSpace height={isMobile ? 30 : 60} />
                  <HomeFAQ />
              </Box>
          </PlatformMarginBox>
          <PlatformSpace height={isMobile ? 30 : 60} />
      </React.Fragment>
    );
}
