import React from 'react';
import { Stack, Typography } from '@mui/material';
import { isMobile } from "react-device-detect";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import PlatformSpace from "../../../components/platform/platformSpace";

export default function AddressRecommendationHome({ open, address, rejectHandler, acceptHandler }) {

    const onAccept = () => {
        acceptHandler();
    };

    const onReject = () => {
        rejectHandler();
    };

    return (
      <Dialog open={open} maxWidth="sm" onClose={rejectHandler} TransitionComponent={React.Fragment}>
          <DialogTitle>
              Your address has been updated, please confirm the changes.
          </DialogTitle>
          <DialogContent>
              <div style={{ margin: 10 }}>
                  <Typography variant="body2" color="textSecondary">
                      Address
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                      {address?.street1 || 'N/A'}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                      {address?.street2 || ''}
                  </Typography>
                  <PlatformSpace height={15} />
                  <Stack direction={isMobile ? "column" : "row"} spacing={1} alignItems={isMobile ? "flex-start" : "center"}>
                      <div style={{ flex: 1 }}>
                          <Typography variant="body2" color="textSecondary">
                              City
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                              {address?.city || 'N/A'}
                          </Typography>
                      </div>
                      <div style={{ flex: 1 }}>
                          <Typography variant="body2" color="textSecondary">
                              State
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                              {address?.state || 'N/A'}
                          </Typography>
                      </div>
                      <div style={{ flex: 1 }}>
                          <Typography variant="body2" color="textSecondary">
                              Zip code
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                              {address?.zip || 'N/A'}
                          </Typography>
                      </div>
                  </Stack>
              </div>
          </DialogContent>
          <DialogActions>
              <Button onClick={onReject}>
                  Reject
              </Button>
              <Button onClick={onAccept}>
                  Accept
              </Button>
          </DialogActions>
      </Dialog>
    );
}