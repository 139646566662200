import * as React from 'react';
import {useContext} from 'react';
import {isMobile} from "react-device-detect";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
import {CatalogItemContextProvider} from "../catalogItemContext";
import CatalogItemHome from "../catalogItemHome";
import DialogActions from "@mui/material/DialogActions";
import {PlatformFormContextProvider} from "../../../../components/platform/platformFormContext";
import AppContext from "../../../../appContext";
import {styled} from "@mui/styles";
import {Link} from "@mui/material";

export default function CatalogItemDialog(props) {
    const {onCloseGridItem, gridItemOpen, gridState} = useContext(AppContext);

    return (
      <PlatformFormContextProvider name="catalogItemForm">
          <Dialog
            open={gridItemOpen}
            onClose={onCloseGridItem}
            maxWidth="xl"
            sx={{
                zIndex: 5001
            }}
            PaperProps={{
                sx: {
                    margin: 0,
                    maxHeight: isMobile ? '95vh' : '80vh',
                    width: '100%',
                    maxWidth: isMobile ? '95vw' : '80vw',
                }
            }}
            transitionDuration={0}
          >
              <DialogTitle>
                  <IconButton onClick={onCloseGridItem} size="small">
                      <Close />
                  </IconButton>
              </DialogTitle>
              <DialogContent>
                  <CatalogItemContextProvider mode="modal" p={gridState?.product} v={gridState?.variant}>
                      <CatalogItemHome />
                  </CatalogItemContextProvider>
              </DialogContent>
              <DialogActions>
                  <LinkButton component="a" onClick={onCloseGridItem}>
                      Continue shopping
                  </LinkButton>
              </DialogActions>
          </Dialog>
      </PlatformFormContextProvider>
    )
}


const LinkButton = styled(Link)(({ theme }) => ({
    marginTop: theme.spacing(1),
    padding: 20,
    textTransform: 'none',
    fontWeight: 800,
    fontSize: '1rem',
    cursor: "pointer",
    justifyContent: 'center',
    textDecoration: 'none', // Added for better link styling
    color: "blue" // Adjust color to match theme
}));
