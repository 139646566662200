import React, {memo, useEffect, useState} from 'react';
import {Annotation, ComposableMap, Geographies, Geography, Marker} from "react-simple-maps";
import {geoCentroid} from "d3-geo";
import {Tooltip} from "@mui/material";

const StateMapUSA = (props) => {
    const [stateData, setStateData] = useState(null);
    const [geoData, setGeoData] = useState(null);
    const [content, setContent] = useState("");

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/map/stateData.json`)
          .then(response => response.json())
          .then(data => setStateData(data))
          .catch(error => console.error('Error fetching JSON:', error));
        fetch(`${process.env.PUBLIC_URL}/map/geoData.json`)
          .then(response => response.json())
          .then(data => setGeoData(data))
          .catch(error => console.error('Error fetching JSON:', error));
    }, []);

    const setTooltipContent = (content) => {
        setContent(content);
    }

    const disabledColor = "white";

    const stateColors = {
        "Alaska": disabledColor,
        "Colorado": disabledColor,
        "Connecticut": disabledColor,
        "Hawaii": disabledColor,
        "Idaho": disabledColor,
        "Nevada": disabledColor,
        "North Dakota": disabledColor,
        "Oregon": disabledColor,
        "Rhode Island": disabledColor,
        "Vermont": disabledColor,
        "Washington": disabledColor,
        "Utah": disabledColor,
    };

    const offsets = {
        VT: [50, -8],
        NH: [34, 2],
        MA: [30, -1],
        RI: [28, 2],
        CT: [35, 10],
        NJ: [34, 1],
        DE: [33, 0],
        MD: [47, 10],
        DC: [49, 21]
    };

    return (
      <div data-tip="">
          <div className="map-container">
              <ComposableMap
                projection="geoAlbersUsa"
                projectionConfig={{scale: 1000}}
                width={980}
                height={551}
                style={{
                    width: "100%",
                    height: "auto",
                    cursor: "pointer"
                }}
              >
                  <Geographies geography={geoData}>
                      {({geographies, projection}) => (
                        <>
                            {geographies.map(geography => {
                                const stateId = geography.properties.name;
                                const fillColor = stateColors[stateId] || "rgb(22,108,75)";

                                return (
                                  <Tooltip title={stateId} key={geography.rsmKey}>
                                      <Geography
                                        key={geography.rsmKey}
                                        geography={geography}
                                        projection={projection}
                                        onMouseEnter={() => {
                                            setTooltipContent(geography.properties.name);
                                        }}
                                        onMouseLeave={() => {
                                            setTooltipContent("");
                                        }}
                                        style={{
                                            default: {
                                                fill: fillColor,
                                                outline: "none",
                                                stroke: "black", // Set the outline to red
                                                strokeWidth: 1,
                                            },
                                            hover: {
                                                fill: "lightgreen",
                                                outline: "none",
                                                stroke: "red", // Set the outline to red
                                                strokeWidth: 0.75,
                                            },
                                            pressed: {
                                                fill: "#013311",
                                                outline: "none",
                                                stroke: "red", // Set the outline to red
                                                strokeWidth: 0.75,
                                            }
                                        }}
                                      />
                                  </Tooltip>
                                );
                            })}
                            {geographies.map(geo => {
                                if (!stateData) {
                                    return <React.Fragment/>
                                }
                                const centroid = geoCentroid(geo);
                                const cur = stateData?.find(s => s.val === geo.id);
                                return (
                                  <g key={geo.rsmKey + "-name"}>
                                      {cur &&
                                        centroid[0] > -160 &&
                                        centroid[0] < -67 &&
                                        (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                                          <Marker coordinates={centroid}>
                                              <text y="2" fontSize={14} textAnchor="middle">
                                                  {cur.id}
                                              </text>
                                          </Marker>
                                        ) : (
                                          <Annotation
                                            subject={centroid}
                                            dx={offsets[cur.id][0]}
                                            dy={offsets[cur.id][1]}
                                          >
                                              <text x={4} fontSize={14} alignmentBaseline="middle">
                                                  {cur.id}
                                              </text>
                                          </Annotation>
                                        ))}
                                  </g>
                                );
                            })}
                        </>)
                      }
                  </Geographies>
              </ComposableMap>
          </div>
      </div>
    )
}

export default memo(StateMapUSA);
