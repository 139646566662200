import * as React from 'react';
import {useContext} from 'react';
import useLayoutOptions from "../../components/hook/useLayoutOptions";
import PublicHeader from "../../components/layout/publicHeader";
import PublicFooter from "../../components/layout/publicFooter";
import CatalogTable from "./catalogTable";
import {Fade, Stack} from "@mui/material";
import {styled} from "@mui/styles";
import CatalogFilter from "./catalogFilter";
import CatalogContext from "./catalogContext";
import {usePageTitle} from "../../components/hook/usePageTitle";
import {isMobile} from "react-device-detect";
import PublicContext from "../../components/layout/publicContext";

export default function CatalogHome(props) {
    const {displayedCategory, fadeIn} = useContext(CatalogContext);
    const {showPromoBanner} = useContext(PublicContext);

    usePageTitle(`Greenbelt.co - ${displayedCategory}`, displayedCategory);

    useLayoutOptions({
        headerComponent: PublicHeader,
        footerComponent: PublicFooter,
        blur: "2px",
        //topMargin: !showPromoBanner && isMobile ? 70 : 30
        topMargin: isMobile ? 70 : 30
    });

    return (
        <CatalogContainer justifyContent="center" alignItems="center">
            <Fade in={fadeIn} timeout={250}>
                <Stack justifyContent="space-between"
                       direction="row"
                       spacing={2}
                       sx={{width: "100vw", padding: isMobile ? 2 : 0.5}}
                       alignItems="flex-start">
                    <CatalogFilter/>
                    <CatalogTable/>
                </Stack>
            </Fade>
        </CatalogContainer>
    );
}

const CatalogContainer = styled(Stack)(({ theme }) => ({
    padding: "0 0 30px 0",
    ...(isMobile && {

    }),
}));
