import {useEffect, useState} from "react";

export default function usePlatformSelect(
    {
        context,
        name,
        required,
        defaultValue,
        options,
        groupBy,
        optionType,
        setValueHandler,
        unbound,
        onChange,
        optionsHandler,
        disabled,
        transformTo
    }) {

    const [value, setValue] = useState("");
    const [valid, setValid] = useState(true);

    useEffect(() => {
        if (context) {
            context.addField(name, {
                name: name,
                groupBy: groupBy,
                unbound: unbound,
                setValue: setFormValue,
                setDefaultValue: setDefaultValue,
                validate: onValidate,
                changeHandler: changeHandler
            });
            if (defaultValue) {
                setDefaultValue(defaultValue);
            }
        }
    }, []);

    useEffect(() => {
        if (disabled && !defaultValue) {
            setValue("");
            context.removeField(name);
        }
    }, [disabled]);

    useEffect(() => {
        if (context && context.updateField) {
            context.updateField(name, groupBy, {unbound: unbound});
            if (unbound === "true") {
                context.removeField(name, groupBy);
            }
        }
    }, [context, name, unbound]);

    const setFormValue = (formValue) => {
        if (optionType === 'OBJECT_ID' && formValue?.id) {
            formValue = formValue.id;
        }

        const innerSetFormValue = (value, opts) => {
            if (!value) {
                setValue("");
            } else if (typeof setValueHandler === "function") {
                const valueToSet = setValueHandler(value, opts);
                setValue(valueToSet);
            } else {
                setValue(value);
            }
        };

        if (typeof optionsHandler === "function") {
            optionsHandler((optionsResult) => {
                innerSetFormValue(formValue, optionsResult);
            });
        } else {
            innerSetFormValue(formValue, options);
        }
    };

    const onValidate = () => {
        const valueToCheck = context.form.current.getValue(name, groupBy);
        const unboundToCheck = context.form.current.isUnbound(name, groupBy);

        if (unboundToCheck === "true") {
            setValid(true);
            return true;
        }

        if (!required) {
            setValid(true);
            return true;
        }

        if (_.isEmpty(valueToCheck)) {
            setValid(false);
            return false;
        }

        setValid(true);
        return true;
    };

    const blurHandler = (event) => {
        if (context != null) {
            context.form.current.onBlur(name, event);
        }
    };

    const changeHandler = (event) => {
        const value = event.target.value;

        setValue(value);

        if (context != null) {
            let valueMutated = value;
            if (typeof transformTo === "function") {
                valueMutated = transformTo(value);
            }
            context.form.current.onChange(name, valueMutated, null, groupBy);
        }

        if (typeof onChange === "function") {
            onChange(value);
        }
    };

    const setDefaultValue = (defaultValue) => {
        if (context != null) {
            context.form.current.setValue(name, defaultValue, groupBy);
        }

        setValue(defaultValue || "");
    };

    return {value, valid, blurHandler, changeHandler, setDefaultValue};
}
