import * as React from 'react';
import {useContext} from 'react';
import GridTilePager from "../shared/gridTilePager/gridTilePager";
import FavoriteVariantContext from "./favoriteVariantContext";
import {styled} from "@mui/material/styles";
import {Box, Button} from "@mui/material";
import {useHistory} from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: 12,
    maxWidth: 325
}));

export default function FavoriteVariantHome({ showButton = false }) { // showButton prop, default to false
    const {variantsWithParent} = useContext(FavoriteVariantContext);
    const history = useHistory();

    const onShopAll = () => {
        history.push("/catalog")
    }

    return (
      <Box
        flex={1}
        display="flex"            // Use flex layout
        flexDirection="column"    // Stack children vertically
        alignItems="center"       // Center horizontally
        justifyContent="center"   // Center vertically
        sx={{ height: '100%' }}   // Ensure the Box takes full height of its container
      >
          <GridTilePager
            titleJustification="center"
            title="Explore our favorites"
            products={variantsWithParent}
          />
          {showButton && (  // Conditionally render the button based on showButton prop
            <StyledButton variant="contained" size="medium" sx={{ mt: 2 }} onClick={onShopAll}>
                Shop all products →
            </StyledButton>
          )}
      </Box>
    );
}
