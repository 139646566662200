import React from 'react';
import {Box, CardContent, CardMedia, Grid, Link, Typography} from '@mui/material';
import PlatformMarginBox from "../../../components/platform/platformMarginBox";
import {styled} from "@mui/material/styles";

const LearnMoreButton = styled(Link)(({ theme }) => ({
    marginTop: theme.spacing(1),
    padding: 0,
    textTransform: 'none',
    fontWeight: 800,
    fontSize: '1rem',
    justifyContent: 'flex-start',
    textDecoration: 'none', // Added for better link styling
    color: "white" // Adjust color to match theme
}));

const CannabisSectionComponent = () => {
    return (
        <PlatformMarginBox>
            <Box bgcolor="rgb(22,108,75)" padding={4} sx={{borderRadius: 4}}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box position="relative">
                            <CardMedia
                                component="img"
                                height="325"
                                image={`${process.env.PUBLIC_URL}/images/hh/WaitLegalTHC.jpg`} // Replace with actual image path
                                alt="Wait...legal THC?"
                                sx={{ borderRadius: 4 }}
                            />
                            <Box
                                position="absolute"
                                top={10}
                                left={16}
                                bgcolor="transparent"
                                color="white"
                                padding={1}
                                zIndex="modal"
                            >
                                <Typography variant="subtitle1" bgcolor="#ffffff3d" padding={1} borderRadius="4px">100% Federally Legal</Typography>
                            </Box>
                        </Box>
                        <CardContent sx={{bgcolor: 'rgb(22,108,75)', color: 'white'}}>
                            <Typography variant="h7" gutterBottom color="white">
                                Wait...legal THC?
                            </Typography>
                            <br/>
                            <Typography variant="body4" color="white">
                                Everything you need to know about our federally legal cannabis
                            </Typography>
                            <br/><br/>
                            <LearnMoreButton component="a" href="/blog/how-is-this-legal">
                                Learn More →
                            </LearnMoreButton>
                        </CardContent>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box position="relative">
                            <CardMedia
                                component="img"
                                height="325"
                                image={`${process.env.PUBLIC_URL}/images/hh/LabTested.jpg`} // Replace with actual image path
                                alt="3rd Party Tested"
                                sx={{ borderRadius: 4 }}
                            />
                            <Box
                                position="absolute"
                                top={10}
                                left={16}
                                bgcolor="transparent"
                                color="white"
                                padding={1}
                                zIndex="modal"
                            >
                                <Typography variant="subtitle1" bgcolor="#ffffff3d" padding={1} borderRadius="4px">Lab Tested</Typography>
                            </Box>
                        </Box>
                        <CardContent sx={{ bgcolor: 'rgb(22,108,75)', color: 'white' }}>
                            <Typography variant="h7" gutterBottom color="white">
                                3rd Party Tested
                            </Typography>
                            <br/>
                            <Typography variant="body4" color="white">
                                Learn about our transparent testing
                            </Typography>
                        </CardContent>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box position="relative">
                            <CardMedia
                                component="img"
                                height="325"
                                image={`${process.env.PUBLIC_URL}/images/hh/DiscreetDelivery.jpg`} // Replace with actual image path
                                alt="Discreet Delivery"
                                sx={{ borderRadius: 4 }}
                            />
                            <Box
                                position="absolute"
                                top={10}
                                left={16}
                                bgcolor="transparent"
                                color="white"
                                padding={1}
                                zIndex="modal"
                            >
                                <Typography variant="subtitle1" bgcolor="#ffffff3d" padding={1} borderRadius="4px">Discreet</Typography>
                            </Box>
                        </Box>
                        <CardContent sx={{bgcolor: 'rgb(22,108,75)', color: 'white'}}>
                            <Typography variant="h7" gutterBottom color="white">
                                Discreet Delivery
                            </Typography>
                            <br/>
                            <Typography variant="body4" color="white">
                                Keep your THC to yourself
                            </Typography>
                        </CardContent>
                    </Grid>
                </Grid>
            </Box>
        </PlatformMarginBox>
    );
};

export default CannabisSectionComponent;
