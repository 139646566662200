import * as React from 'react';
import CatalogShopAllTable from "./catalogShopAllTable";

export default function CatalogShopAllHome(props) {

    return (
        <React.Fragment>
            {/*<Typography variant="h3" component="h2" textAlign="center" my={2}>*/}
            {/*    Cannabis For Every Occasion*/}
            {/*</Typography>*/}
            {/*<PlatformSpace height={30}/>*/}
            <CatalogShopAllTable/>
        </React.Fragment>
    )
}