import React, {useState} from 'react';
import {Box, Grid, Link, Paper, Stack, Typography} from "@mui/material";
import {styled} from "@mui/styles";

const LearnSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    width: "90vw"
}));

const ImagePaper = styled(Paper)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    height: '100%',
    backgroundColor: 'transparent', // Remove white background
    boxShadow: 'none' // Remove any shadow if not needed
}));

const Image = styled('img')({
    display: 'block',
    width: '100%',
    height: 'auto',
    borderRadius: 20
});

const LearnLinks = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}));

const StyledLink = styled(Link)(({ theme }) => ({
    color: 'rgba(9,45,33,0.9) !important',
    cursor: "pointer !important", // Ensure cursor is pointer
    textDecoration: "none !important",
    '&:hover': {
        textDecoration: 'underline',
    },
    margin: '8px 0',
}));

export default function PublicLearnMenu(props) {
    const [imageSrc, setImageSrc] = useState("/images/hh/IsGreenbeltLegal.jpg");

    // Define the links and their corresponding images
    const links = [
        {
            url: "/blog/how-is-this-legal",
            label: "Is Greenbelt Legal?",
            img: `/images/hh/IsGreenbeltLegal.jpg`
        },
        {
            url: "/blog/what-is-thca",
            label: "What is THCa?",
            img: `/images/hh/WhatIsTHCA.jpg`
        },
        {
            url: "/blog/our-cannabinoids",
            label: "What Are Cannabinoids?",
            img: `/images/hh/WhatAreCannabinoids.jpg`
        },
        {
            url: "/blog/high-quality",
            label: "What Makes Greenbelt So High Quality?",
            img: `/images/hh/WhatMakesGreenbeltQuality.jpg`
        },
        {
            url: "/blog/our-process",
            label: "The Greenbelt Process",
            img: `/images/hh/OurProcessToTheLight.jpg`
        }
    ];

    return (
        <Stack direction="row" spacing={0} justifyContent="center" alignItems="center">
            <LearnSection>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <Image
                            src={`${process.env.PUBLIC_URL}${imageSrc}`}
                            alt="Cannabis plant"
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <LearnLinks>
                            <Typography variant="body4" gutterBottom>
                                LEARN
                            </Typography>
                            {links.map((link, index) => (
                                <StyledLink
                                    key={index}
                                    href={link.url}
                                    variant="h5"
                                    onMouseOver={() => setImageSrc(link.img)}
                                    onMouseOut={() => setImageSrc("/images/hh/IsGreenbeltLegal.jpg")}
                                >
                                    {link.label}
                                </StyledLink>
                            ))}
                        </LearnLinks>
                    </Grid>
                </Grid>
            </LearnSection>
        </Stack>
    );
}
