import React from 'react';
import {Box, Button, Container, Grid, Typography} from '@mui/material';
import {isMobile} from "react-device-detect";
import {styled} from "@mui/material/styles";
import {useHistory} from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: 12,
    maxWidth: 325
}));

const TrendingProduct = ({ product, clickHandler }) => (
    <Box sx={{ textAlign: 'center' }}>
        <img
            src={product.imageUrl}
            alt={product.title}
            style={{ width: '100%', borderRadius: '8px' }}
        />
        <Typography variant="h6" sx={{ mt: 2 }}>
            {product.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {product.description}
        </Typography>
        <StyledButton variant="contained" size="medium" sx={{ mt: 2 }} onClick={() => clickHandler(product.productLink)}>
            {product.buttonText}
        </StyledButton>
    </Box>
);

export default function HomeTrendingNow() {
    const history = useHistory();

    const onClick = (link) => {
        history.push(link)
    }

    const products = [
        {
            title: 'Delicious Delta-8 Gummies',
            description: 'Euphoric relaxation for cannabis enthusiasts',
            imageUrl: `${process.env.PUBLIC_URL}/images/hh/trending_gummies.jpg`,
            buttonText: 'SHOP D8 GUMMIES',
            productLink: "/catalog/gummies?Cannabinoid=Delta8"
        },
        {
            title: 'THCA Liquid Diamond 1g Vapes',
            description: 'Stronger and Longer Lasting',
            imageUrl: `${process.env.PUBLIC_URL}/images/hh/trending_vapes.jpg`,
            buttonText: 'SHOP VAPE DISPOSABLES',
            productLink: "/catalog/vapes?Brand=Disposable%20Vapes"
        },
        {
            title: 'Freshly Harvested THCA Flower',
            description: 'Connoisseur-grade & Indoor Grown',
            imageUrl: `${process.env.PUBLIC_URL}/images/hh/trending_flower.jpg`,
            buttonText: 'SHOP THCA STRAINS',
            productLink: "/catalog/flower"
        },
    ];

    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Typography variant={isMobile ? "h4" : "h3"} gutterBottom textAlign="center">
                    Trending Now
                </Typography>
                <Typography variant="h8" component="h2" textAlign="center" my={3}>
                    See what all the hype is about
                </Typography>
                <Grid container spacing={4} justifyContent="center" sx={{padding: isMobile ? "0 20px 0 20px" : "inherit"}}>
                    {products.map((product, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4}>
                            <TrendingProduct product={product} clickHandler={onClick} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
}
