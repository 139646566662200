import React from 'react';
import {Box, Typography} from '@mui/material';
import PublicLayout from "../../../components/layout/publicLayout";
import {isMobile} from "react-device-detect";
import PlatformSpace from "../../../components/platform/platformSpace";
import PlatformMarginBox from "../../../components/platform/platformMarginBox";
import BlogWithMoodBanner from "../../../components/shared/blog/blogWithMoodBanner";

export default function BlogWhatIsThcaIndex() {
    return (
        <PublicLayout>
            <BlogWithMoodBanner
                title="What is THCa?"
                image={`${process.env.PUBLIC_URL}/images/hh/WhatIsTHCA.jpg`}
            />
            <PlatformSpace height={isMobile ? 30 : 60}/>
            <PlatformMarginBox>
                <Box flex={1}>
                    <Typography variant="h5" gutterBottom>
                        THCa stands as a cannabinoid precursor found in the cannabis plant, which transforms into the well-known THC, the active compound that elicits the marijuana "high," upon exposure to heat.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="body1" gutterBottom>
                        Despite sharing similarities with marijuana, THCa is considered federally legal in the United States. This overview will delve into THCa's properties, its psychoactive potential upon activation, legal considerations, usage recommendations, and its purported health benefits.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        The Distinction Between THCa and THC
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        In its raw form, THCa, also known as tetrahydrocannabinolic acid, resides within the fresh cannabis plant and lacks psychoactive effects. It's the heating process, or decarboxylation, that converts THCa to the intoxicating THC. Thus, no THC can be produced without the presence of THCa.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        The Experience of Consuming THCa
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        In its unaltered state, THCa won't induce the psychoactive "high" that THC does. When subjected to heat, though, THCa evolves into an incredibly psychoactive substance. The resulting experience is comparable to the high-quality cannabis one might obtain from a dispensary.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        At Greenbelt, we focus on selecting strains with precise cannabinoid and terpene compositions to reliably predict the effects, from sociability and relaxation to creativity and euphoria.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        Guidelines for THCa Consumption
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Since THCa converts to the potent THC upon heating, the key to dosing is starting low, particularly for novices, and incrementally adjusting based on one's tolerance and desired outcome. Those with more experience may require larger doses for the same effect. It's important to be patient and mindful of the onset time for these psychoactive effects.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        Potential Advantages of THCa
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        While studies on THCa are still in preliminary phases, anecdotal evidence suggests it might provide relief for minor discomfort, occasional stress, and sleep disturbances. Nonetheless, comprehensive research is necessary to substantiate these claims fully.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        The Legality of THCa
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Federally, THCa is permissible under the terms of the 2018 Farm Bill, which sanctioned hemp cultivation and excluded hemp and its derivatives, such as THCa with less than 0.3% THC, from the DEA's Controlled Substances list. While Greenbelt Products adhere to this threshold, it's crucial to remain informed about state-specific cannabis regulations.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        Drug Testing and THCa
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        THCa may be detectable in drug screenings, as opposed to CBD, which generally is not. The possibility of testing positive can be influenced by the type of drug test administered and the specific THCa products used. The detectability of cannabinoids varies based on numerous factors, such as:
                    </Typography>
                    <Typography component="div" variant="body1" gutterBottom>
                        <ul>
                            <li>The specific cannabinoid: For instance, THC has a longer detection window than CBD.</li>
                            <li>The consumption method: Smoking can be detected more quickly than ingestion through edibles.</li>
                            <li>Usage frequency: Regular consumption can result in longer detection times.</li>
                            <li>Metabolic rate and body composition: Metabolism speed and body fat percentage can influence how long cannabinoids remain detectable.</li>
                            <li>Hydration level: Adequate water intake can aid in the elimination of cannabinoids from the body.</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Although these guidelines offer a general framework, the actual duration cannabinoids stay in the body can significantly differ from person to person.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        Greenbelt's Selection Process for THCa Flower
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Our partnership with small-scale American farms involves a careful breeding process for cannabis that yields Greenbelt's unique mood-inducing profiles through a high THCa content and specific terpene-cannabinoid ratios. The dedication to quality cultivation is what distinguishes Greenbelt's cannabis from commonplace offerings. Our cultivators employ decades of expertise and sustainable practices to produce superior cannabis that is distinctly Greenbelt.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        Discover the Quality of Our Growers
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        The cannabis sourced for Greenbelt products comes from growers who eschew pesticides and have honed their cultivation skills over many years. The growers' commitment to excellence and their proprietary strains are evident in the unique sensory qualities of Greenbelt's cannabis. It's this attention to detail that sets Greenbelt apart, providing a cannabis experience that transcends the ordinary.
                    </Typography>
                </Box>
            </PlatformMarginBox>
            <PlatformSpace height={isMobile ? 30 : 60}/>
        </PublicLayout>
    );
};
