import * as React from 'react';
import {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {styled} from "@mui/styles";

export default function CatalogFilterControl({alwaysExpanded, title, icon, content}) {
    const [expanded, setExpanded] = useState(alwaysExpanded);
    const TitleIcon = icon;

    const onChange = (event, expanded) => {
        setExpanded(expanded);
    }

    const accordionProps = {
        mountOnEnter: true,
        unmountOnExit: true,
        timeout: { enter: 0, exit: 0 }
    }

    return (
        <FilterContainer
            onChange={onChange}
            expanded={alwaysExpanded}
            defaultExpanded={alwaysExpanded}
            TransitionProps={accordionProps}>
            {title &&
                <Filter expandIcon={!alwaysExpanded ? <ExpandMore /> : null}>
                    {title}
                </Filter>
            }
            <AccordionDetails>
                {content}
            </AccordionDetails>
        </FilterContainer>
    )
}

const FilterContainer = styled(Accordion)(({ theme }) => ({
    "&.MuiCollapse-root": {
        backgroundColor: "white !important"
    },
    "&.Mui-expanded": {
        margin: 0
    },
    "&. MuiAccordionSummary-content": {
        fontSize: "1.2rem !important",
    },
    "&:before": {
        backgroundColor: "transparent !important"
    }
}));

const Filter = styled(AccordionSummary)(({ theme }) => ({
    "& .MuiAccordionSummary-content": {
        fontSize: "1.2rem !important",
        color: theme.custom.primaryTextColor
    },
    "&:hover": {
        // backgroundColor: "rgb(41, 31, 55) !important",
        // transition: "color .3s ease-out",
        // borderRadius: "12px !important"
    }
}));