import * as React from 'react';
import {useContext, useEffect, useRef} from 'react';
import Drawer from '@mui/material/Drawer';
import PublicContext from "./publicContext";
import {styled} from "@mui/styles";
import {Divider, Link, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Close} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import PlatformSpace from "../platform/platformSpace";
import {useHistory} from "react-router-dom";
import Badge from "@mui/material/Badge";
import CartContext from "../../views/cart/cartContext";
import formatToUSD from "../util/moneyUtil";
import PlatformButton from "../platform/platformButton";
import {isMobile} from "react-device-detect";
import {getCartImageUrl} from "../util/cart";
import AppContext from "../../appContext";

export default function PublicCart() {
    const {showCart, setShowCart} = useContext(PublicContext);
    const {cart, emptyCart, getCart} = useContext(CartContext);
    const {onCloseGridItem} = useContext(AppContext);
    const history = useHistory();
    const cartContainerRef = useRef(null);

    const scrollToBottom = () => {
        const element = cartContainerRef.current;
        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    }

    useEffect(() => {
        if (isMobile && showCart === true) {
            setTimeout(() => {
                scrollToBottom();
            }, 1250)
        }
    }, [showCart]);

    const onCart = () => {
        onCloseGridItem();
        history.push("/cart");
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowCart(open);
    };

    const onEmptyCart = () => {
        emptyCart();
    }

    const onHideCart = () => {
        setShowCart(false);
    }

    const onContinueShopping = () => {
        onHideCart();
    }

    const totalQuantity = cart?.cartItems?.reduce((sum, item) => sum + item.quantity, 0) || 0;
    const totalItems = totalQuantity
        ? `${totalQuantity} ${totalQuantity === 1 ? 'item' : 'items'}`
        : "Your cart is empty";

    return (
        <Drawer
            transitionDuration={{
                appear: 225,
                enter: 225,
                exit: 100
            }}
            PaperProps={{
                sx: {
                    boxShadow: "none",
                    backgroundColor: "rgba(0, 0, 0, 0) !important",
                    borderRadius: 4,
                    padding: 3,
                },
            }}
            sx={{
                zIndex: 10000
            }}
            anchor="right"
            open={showCart}
            onClose={toggleDrawer( false)}>
            <CartContainer ref={cartContainerRef}>
                <CartHeader>
                    <Stack justifyContent="space-between" direction="row">
                        <Typography variant="h7" sx={{color: "black"}}>
                            Your cart
                        </Typography>
                        <IconButton sx={{height: 25}}>
                            <Close sx={{color: "black"}} fontSize="medium" onClick={onHideCart} />
                        </IconButton>
                    </Stack>
                </CartHeader>
                <CartDivider/>
                <CartContent>
                    <Stack justifyContent="space-between" direction="row" alignItems="center">
                        <Typography variant="body3" sx={{color: "black"}}>
                            {totalItems}
                        </Typography>
                        <ClearAllLink variant="body4" onClick={onEmptyCart}>
                            Clear all
                        </ClearAllLink>
                    </Stack>
                    <PlatformSpace height={30}/>
                    {cart && <React.Fragment>
                        {cart?.cartItems?.map((cartItem) => {
                            const imageUrl = getCartImageUrl(cartItem);
                            return (
                                <React.Fragment key={cartItem.id}>
                                    <Stack justifyContent="space-between" direction="row" alignItems="center">
                                        <Stack spacing={2} justifyContent="flex-start" direction="row" alignItems="center">
                                            <Badge badgeContent={cartItem.quantity} color="primary">
                                                <Image src={imageUrl} />
                                            </Badge>
                                            <div>
                                                <Typography variant="body4" sx={{color: "black"}}>
                                                    {cartItem.variantName}
                                                </Typography>
                                                <br/>
                                                <Typography variant="body5" sx={{color: "black"}}>
                                                    {cartItem.name}
                                                </Typography>
                                            </div>
                                        </Stack>
                                        <Typography variant="body5" sx={{color: "black"}}>
                                            {formatToUSD(cartItem.total)}
                                        </Typography>
                                    </Stack>
                                    <PlatformSpace height={30}/>
                                </React.Fragment>
                            )
                        })}
                    </React.Fragment>}
                </CartContent>
                <CartDivider/>
                {totalQuantity > 0 &&
                    <CartContent>
                        <Stack justifyContent="space-between" direction="row" alignItems="center">
                            <Typography variant="body3" sx={{color: "black"}}>
                                Total price
                            </Typography>
                            <Typography variant="body3" sx={{color: "black"}}>
                                {formatToUSD(cart?.cartTotalAmount)}
                            </Typography>
                        </Stack>
                    </CartContent>
                }
                <PlatformSpace height={30}/>
                <CartContent style={{textAlign: "center"}}>
                    {totalQuantity > 0 &&
                        <PlatformButton fullWidth={true} size="lg" onClick={onCart}>
                           View cart & checkout
                        </PlatformButton>
                    }
                    {(totalQuantity <= 0 && !isMobile) &&
                        <PlatformButton size="lg" onClick={onContinueShopping}>
                            Continue Shopping
                        </PlatformButton>
                    }
                    {isMobile &&
                        <React.Fragment>
                            <PlatformSpace height={20}/>
                            <LinkButton component="a" onClick={onHideCart}>
                                Continue shopping
                            </LinkButton>
                            <PlatformSpace height={30}/>
                        </React.Fragment>
                    }
                </CartContent>
            </CartContainer>
        </Drawer>
    );
}

const LinkButton = styled(Link)(({ theme }) => ({
    marginTop: theme.spacing(1),
    padding: 0,
    textTransform: 'none',
    fontWeight: 800,
    fontSize: '1rem',
    justifyContent: 'center',
    textDecoration: 'none', // Added for better link styling
    color: "blue" // Adjust color to match theme
}));

const ClearAllLink = styled(Typography)(({ theme }) => ({
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
        color: 'blue'  // Change this to the color you want on hover
    }
}));

const Image = styled('img')(({ size }) => ({
    opacity: 0.99,
    objectFit: "cover",
    width: 75,
    height: 75,
    borderRadius: "12px",
    pointerEvents: "none",
    backgroundColor: "black"
}));

const CartButton = styled(Button)(({ theme }) => ({
    height: 65,
    width: "100% !important",
    backgroundImage: "none !important",
    color: "white !important"
}));

const CartHeader = styled('div')(({ fullWidth }) => ({
    padding: "30px 25px 10px 25px"
}));

const CartContent = styled('div')(({ fullWidth }) => ({
    padding: "10px 25px 10px 25px",
}));

const CartDivider = styled(Divider)(({ fullWidth }) => ({
    borderColor: "rgba(0, 0, 0, 0.1) !important"
}));

const CartContainer = styled('div')(({ fullWidth }) => ({
    minWidth: isMobile ? "88vw" : "25vw",
    height: "100vh",
    maxHeight: 1000,
    backgroundImage: "linear-gradient(to top, rgb(255, 255, 255), rgba(15, 81, 53, 0.3))",
    backgroundColor: "rgba(255, 255, 255, 255) !important",
    scrollBehavior: 'smooth',
    borderRadius: 20,
    overflowY: "scroll"
}));