import React from 'react';
import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';

const LogoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    backgroundColor: "rgba(9,45,33,1)",
    overflowX: 'hidden',
    padding: theme.spacing(2, 0),
}));

const LogoBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '100%',
}));

const StyledLogo = styled('img')(({ theme }) => ({
    height: 50,
    margin: theme.spacing(1),
    maxWidth: '100%',
}));

export default function HomeAsSeenIn() {
    const logos = [
        { src: `${process.env.PUBLIC_URL}/images/hh/AsSeenTexasMonthly.png`, alt: "Texas Monthly" },
        { src: `${process.env.PUBLIC_URL}/images/hh/AsSeenBestOf2023.png`, alt: "As Seen Best Of 2023" },
        { src: `${process.env.PUBLIC_URL}/images/hh/AsSeenTexasChoice.png`, alt: "As Seen Texas Choice" },
        { src: `${process.env.PUBLIC_URL}/images/hh/AsSeenAustinMonthly.png`, alt: "As Seen Austin Monthly" },
        { src: `${process.env.PUBLIC_URL}/images/hh/AsSeenCBS.png`, alt: "As Seen CBS" },
        { src: `${process.env.PUBLIC_URL}/images/hh/AsSeenStatesman.png`, alt: "As Seen Statesman" },
    ];

    return (
        <LogoContainer>
            <LogoBox>
                {logos.map((logo, index) => (
                    <StyledLogo key={index} src={logo.src} alt={logo.alt} />
                ))}
            </LogoBox>
        </LogoContainer>
    );
}
