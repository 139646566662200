export default function formatToUSD(value) {
    if (isNaN(value)) {
        return '--';
    }

    // Format the number to USD currency
    let formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);

    // Check if the formatted value ends with '.00' and adjust if necessary
    if (formatted.endsWith('.00')) {
        return formatted.slice(0, -3);
    }
    return formatted;
}
