import React from 'react';
import {Box, Card, CardContent, Grid, Typography} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import {isMobile} from "react-device-detect";

const BackgroundBox = styled(Box)(({ theme, isMobile }) => ({
    backgroundImage: isMobile
        ? "radial-gradient(circle at bottom center, transparent 50%, white 100%)," +
        "radial-gradient(circle at top center, transparent 50%, white 100%)," +
        "radial-gradient(circle at bottom right, transparent 50%, rgba(173, 216, 230, 0.2) 100%)," +
        "radial-gradient(circle at -30% 50%, transparent 50%, rgba(0, 255, 0, 0.2) 100%)"
        : "radial-gradient(circle at bottom center, transparent 50%, white 100%)," +
        "radial-gradient(circle at top center, transparent 50%, white 100%)," +
        "radial-gradient(circle at bottom right, transparent 50%, rgba(173, 216, 230, 0.2) 100%)," +
        "radial-gradient(circle at -30% 50%, transparent 50%, rgba(0, 255, 0, 0.2) 100%)",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: theme.spacing(4, 0),
    overflowX: 'hidden', // Prevent horizontal overflow
}));

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: isMobile ? "80vw" : "15vw", // fixed width for the cards
    minHeight: 325, // fixed height for consistency
    margin: 'auto', // centers the card in the grid item
    padding: theme.spacing(0),
    borderRadius: 12,
    backgroundColor: 'rgba(255, 255, 255, 0.83)',
    boxShadow: theme.shadows[3],
}));

const FeatureIcon = styled('img')({
    width: '100px',
    height: '100px',
    margin: '25px 16px 0 16px',
});

const StyledCardContent = styled(CardContent)({
    textAlign: 'center',
});

const LogoBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '100%', // Prevent overflow within the box
}));

const LogoContainer = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    width: "100%",
    backgroundColor: "rgba(225,255,227,0.76)",
    overflowX: 'hidden', // Prevent horizontal overflow
}));

const StyledLogo = styled('img')(({ theme }) => ({
    width: 'auto',
    height: 75,
    margin: theme.spacing(1),
    maxWidth: '100%', // Ensure logo does not overflow
}));

const featureData = [
    {
        icon: `${process.env.PUBLIC_URL}/images/hh/DEASafety.png`,
        title: 'Guaranteed Safety',
        description: 'Full-panel testing for potency and all contaminants.',
    },
    {
        icon: `${process.env.PUBLIC_URL}/images/hh/HandCrafted.png`,
        title: 'Artisanal Quality',
        description: 'Handcrafted in our GMP-certified facility and infused for product consistency.',
    },
    {
        icon: `${process.env.PUBLIC_URL}/images/hh/Founded.png`,
        title: 'Founded 2018',
        description: 'Boasting five years of pioneering experience in the cannabis industry, we\'ve consistently led the way in innovation.',
    },
    {
        icon: `${process.env.PUBLIC_URL}/images/hh/ResponsiblySourced.png`,
        title: 'Ethically Sourced',
        description: 'Grown without chemical pesticides or fertilizers on our Texas farm.',
    },
];

const logos = [
    {
        src: `${process.env.PUBLIC_URL}/images/hh/LabTestedIcon.png`,
        alt: "Lab Tested"
    },
    {
        src: `${process.env.PUBLIC_URL}/images/hh/GMPCertified.png`,
        alt: "GMP Certified Logo"
    },
    {
        src: `${process.env.PUBLIC_URL}/images/hh/CrueltyFree.png`,
        alt: "Cruelty-Free Logo"
    },
    {
        src: `${process.env.PUBLIC_URL}/images/hh/MadeInUSA.png`,
        alt: "Made in USA Logo"
    },
];

export default function HomeEthos() {
    const theme = useTheme();

    return (
        <Box>
            <BackgroundBox>
                <Grid container spacing={3} justifyContent="center" sx={{ px: isMobile ? 0 : 20, maxWidth: isMobile ? null : '90%', mx: isMobile ? null : 'auto' }}>
                    {featureData.map((feature, index) => (
                        <Grid item key={index} xs={12} sm={6} md={3}>
                            <StyledCard>
                                <FeatureIcon src={feature.icon} alt={feature.title} />
                                <StyledCardContent>
                                    <Typography variant="h6">{feature.title}</Typography>
                                    <Typography variant="body2">{feature.description}</Typography>
                                </StyledCardContent>
                            </StyledCard>
                        </Grid>
                    ))}
                </Grid>
            </BackgroundBox>
            <LogoContainer>
                <LogoBox>
                    {logos.map((logo, index) => (
                        <StyledLogo key={index} src={logo.src} alt={logo.alt} />
                    ))}
                </LogoBox>
            </LogoContainer>
        </Box>
    );
}
