import React, {useEffect, useState} from 'react';
import {Box, Modal, Typography, useMediaQuery} from '@mui/material';
import {styled} from "@mui/styles";
import {createTheme} from "@mui/material/styles";
import PlatformButton from "../../../components/platform/platformButton";
import {isMobile} from "react-device-detect";
import useScrollBlock from "../../../components/hook/useScrollBlock";

export default function AgeGateHome() {
    const theme = createTheme();
    const [open, setOpen] = useState(!Boolean(localStorage.getItem("ageGate")));
    const [blockScroll, allowScroll] = useScrollBlock(); // Use the hook
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        //blockScroll();

        // Cleanup function to re-enable scrolling when the component unmounts or modal is closed
        return () => {
            allowScroll(); // Re-enable scrolling
        };
    }, [open, blockScroll, allowScroll]);

    const handleClose = () => {
        setOpen(false);
        allowScroll(); // Re-enable scrolling when modal closes
        localStorage.setItem("ageGate", "true");
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };

    // ResponsiveBox with conditional styles for mobile
    const ResponsiveBox = styled(Box)(({ theme }) => ({
        position: 'fixed',
        top: isMobile ? '50%' : '10%',
        right: isMobile ? 'auto' : '2.5%',
        left: isMobile ? '50%' : 'auto',
        transform: isMobile ? 'translate(-50%, -50%)' : 'none',
        backgroundColor: 'rgba(9,45,33,0.9)',
        borderRadius: 20,
        width: isMobile ? '90vw' : '40vw',
        zIndex: theme.zIndex.drawer + 1,
        padding: isMobile ? theme.spacing(4) : "75px 35px 75px 35px",
        boxShadow: 24,
    }));

    // Image with conditional styles for mobile
    const Image = styled('img')(({ theme }) => ({
        position: 'absolute',
        top: isMobile ? '10%' : theme.spacing(10),
        left: isMobile ? '50%' : theme.spacing(10),
        transform: isMobile ? 'translateX(-50%)' : 'none',
        cursor: "pointer",
    }));

    const modalStyle = {
        height: '100vh',
        width: '100vw',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'fixed',
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg_dood.webp)`,
        top: 0,
        left: 0,
        bgcolor: 'background.paper',
        opacity: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 4,
    };

    return (
        <Modal
            sx={{ zIndex: 5001 }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Image src={`${process.env.PUBLIC_URL}/images/logo_gb.png`} />
                <ResponsiveBox>
                    <Typography sx={{ color: "white" }} variant={matches ? 'h3' : 'h6'} component="h2">
                        Welcome! You must be 21+ to enter this site.
                    </Typography>
                    <PlatformButton sx={{width: 125, mt: 2}} onClick={handleClose} size="lg" fullWidth={false}>
                        Enter&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                                   viewBox="0 0 256 256" font-size="16">
                        <path
                            d="M224.49,136.49l-72,72a12,12,0,0,1-17-17L187,140H40a12,12,0,0,1,0-24H187L135.51,64.48a12,12,0,0,1,17-17l72,72A12,12,0,0,1,224.49,136.49Z"></path>
                    </svg>
                    </PlatformButton>
                </ResponsiveBox>
            </Box>
        </Modal>
    );
}
