import React, {useContext, useEffect, useState} from "react";
import PublicFooter from "./components/layout/publicFooter";
import PublicHeader from "./components/layout/publicHeader";
import HomeApi from "./views/home/homeApi";

export const AppContext = React.createContext({
    onLayoutReset: () => {}
});
export default AppContext;

export const useAppContext = () => useContext(AppContext);

const DEFAULT_OPTIONS = {
    backgroundComponent: null,
    footerComponent: PublicFooter,
    headerComponent: PublicHeader
};

export const AppContextProvider = ({children}) => {
    const [layoutOptions, setLayoutOptions] = useState(DEFAULT_OPTIONS);
    const [backgroundSize, setBackgroundSize] = useState(null);
    const [subscribed, setSubscribed] = useState(false);
    const [showPromoBanner, setShowPromoBanner] = useState(true);
    const [tabIndex, setTabIndex] = useState(0);
    const [categories, setCategories] = useState(null);
    const [store, setStore] = useState(null);
    const [gridItemOpen, setGridItemOpen] = useState(false);
    const [gridState, setGridState] = useState(null);

    useEffect(() => {
        HomeApi.getCategories((categories) => {
            const sortedCategories = categories.sort((a, b) => a.sortOrder - b.sortOrder);
            setCategories(sortedCategories);
        });
        HomeApi.getStore((store) => {
            setStore(store);
        })
    }, []);

    const onLayoutOptions = (updateOptions) => {
        setLayoutOptions({
            backgroundComponent: updateOptions.backgroundComponent || layoutOptions.backgroundComponent,
            footerComponent: updateOptions.footerComponent || layoutOptions.footerComponent,
            headerComponent: updateOptions.headerComponent || layoutOptions.headerComponent,
            ...updateOptions
        });
    }

    const onLayoutReset = () => {
        setLayoutOptions(DEFAULT_OPTIONS);
    }

    const onBackgroundSize = (backgroundSize) => {
        setBackgroundSize(backgroundSize);
    }

    const onCloseGridItem = () => {
        setGridItemOpen(false);
    }

    const onOpenGridItem = (product, variant) => {
        setGridState({product, variant})
        setGridItemOpen(true);
    }

    return (
        <AppContext.Provider
            value={{
                onLayoutOptions,
                onLayoutReset,
                onBackgroundSize,
                onCloseGridItem,
                onOpenGridItem,
                gridState,
                showPromoBanner,
                setShowPromoBanner,
                store,
                layoutOptions,
                backgroundSize,
                subscribed,
                setSubscribed,
                tabIndex,
                setTabIndex,
                categories,
                gridItemOpen
            }}
        >
            <>{children}</>
        </AppContext.Provider>
    );
}