import React from 'react';
import {Box, Typography} from '@mui/material';
import PublicLayout from "../../../components/layout/publicLayout";
import {isMobile} from "react-device-detect";
import PlatformSpace from "../../../components/platform/platformSpace";
import BlogWithMoodBanner from "../../../components/shared/blog/blogWithMoodBanner";
import PlatformMarginBox from "../../../components/platform/platformMarginBox";

export default function AboutUsIndex() {
    return (
      <PublicLayout>
          <BlogWithMoodBanner
            title="About Greenbelt"
            image={`${process.env.PUBLIC_URL}/images/aboutus/oldschool.jpg`}
          />
          <PlatformMarginBox>
              <Box flex={1}>
                  <Typography variant="h5" gutterBottom>
                      Our Story
                  </Typography>
                  <PlatformSpace height={isMobile ? 30 : 60}/>
                  <Typography variant="body1" gutterBottom>
                      We recognize that everyone has unique needs, and fulfilling those needs should be straightforward.
                      That’s why we launched Greenbelt, an online destination for 100% federally legal cannabis, sourced
                      directly from top-tier American farms, tailored to enhance your daily experiences.
                  </Typography>
                  <PlatformSpace height={isMobile ? 30 : 60}/>
                  <Typography variant="h5" gutterBottom>
                      The Foundation of Our Mission
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                      Our aim is for cannabis to be accessible, affordable, convenient, and transparent, focusing
                      particularly on backing the American farmers who are essential to our operations.
                  </Typography>
                  <PlatformSpace height={isMobile ? 30 : 60}/>
                  <Typography variant="h5" gutterBottom>
                      Our Story and Values
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                      Initiated in 2018 by founders The Zain Family, Greenbelt has expanded to employ over
                      100 full-time staff globally, complemented by a strong network of small U.S. farmers and partners
                      in manufacturing. We have forged relationships with over 50 small American farms, strengthening
                      local economies and championing sustainable agricultural methods. Fundamental to our operation
                      is a commitment to superior product quality and delivering unparalleled customer service. Our
                      organizational culture encourages open communication and inclusivity: every employee's voice is
                      valued, and we are devoted to improving the welfare of both our staff and customers.
                  </Typography>
                  <PlatformSpace height={isMobile ? 30 : 60}/>
                  <Typography variant="h5" gutterBottom>
                      Impact and Social Responsibility
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                      Commitment to social impact is a cornerstone of our business philosophy, not just a secondary
                      concern. This year, we have contributed substantially to pet humane societies and supported
                      political candidates who are advocates for cannabis, furthering a movement towards its wider
                      recognition and acceptance.
                  </Typography>
              </Box>
          </PlatformMarginBox>
          <PlatformSpace height={isMobile ? 30 : 60}/>
      </PublicLayout>
    );
};
