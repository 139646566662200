import React, {useContext, useEffect} from 'react';
import {ClickAwayListener} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import PublicMegaMenuContext from "./publicMegaMenuContext";
import {styled} from "@mui/styles";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

export default function PublicMegaMenu({title, width, children}) {
    const {addAnchor, showAnchor, hideAnchor, getAnchor} = useContext(PublicMegaMenuContext);

    useEffect(() => {
        addAnchor(title);
    }, [title]);

    const handleOnClick = (event) => {
        const anchor = getAnchor(title);
        if (anchor) {
            hideAnchor(title);
        } else {
            showAnchor(title, event.currentTarget);
        }
    };

    const isAnchor = getAnchor(title);

    return (
        <div>
            <div style={{position: 'relative'}}>
                <div
                    style={{
                        cursor: "pointer",
                        position: 'absolute',
                        height: 40,
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1 // Ensure this is above the StyledButton
                    }}
                    onMouseOver={handleOnClick}
                    onClick={handleOnClick}
                />
                <StyledButton
                    variant="text"
                    width={width}
                    endIcon={isAnchor ? <StyledExpandMore /> : <StyledExpandLess />}
                >
                    {title}
                </StyledButton>
            </div>
            {isAnchor &&
                <ClickAwayListener onClickAway={() => hideAnchor(title)}>
                    <StyledPaper
                        onMouseLeave={() => hideAnchor(title)}
                        elevation={2}
                    >
                        {children}
                    </StyledPaper>
                </ClickAwayListener>
            }
        </div>
    );
}

const StyledButton = styled(Button)(({theme, width}) => ({
    backgroundImage: "inherit !important",
    backgroundColor: "inherit !important",
    minWidth: width ? `${width}px !important` : "inherit",
    textTransform: "none",
    cursor: "pointer",
    height: 40,
    padding: "0 8px 0 8px !important",
    fontWeight: 600,
    fontSize: "1.0rem",
    color: "black !important",
    "&:hover": {
        borderRadius: 0,
        color: "#efef",
    },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: "#f7f6f2 !important",
    // backgroundImage:
    //     "radial-gradient(circle at bottom center, transparent 50%, white 100%)," +
    //     "radial-gradient(circle at top center, transparent 50%, white 100%)," +
    //     "radial-gradient(circle at bottom right, transparent 50%, rgba(173, 216, 230, 0.5) 100%)," +
    //     "radial-gradient(circle at -30% 50%, transparent 50%, rgba(0, 255, 0, 0.5) 100%)",
    // backgroundColor: "rgba(173, 216, 230, 1) !important",
    position: "absolute",
    borderRadius: 0,
    zIndex: 4000,
    width: "100%",
    maxWidth: "100%",
    padding: "30px 50px",
    left: 0,
    right: 0,
}));

const StyledExpandMore = styled(ExpandMore)(({ theme }) => ({
    color: "white",
}));

const StyledExpandLess = styled(ExpandLess)(({ theme }) => ({
    color: "white",
}));