import React from 'react';
import {styled} from "@mui/styles";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

export default function PublicMenuButton({width, href, children}) {
    const history = useHistory();

    const goToLink = () => {
        history.push(href);
    }

    return (
        <StyledButton
            onClick={goToLink}
            width={width}
            variant="text"
        >
            {children}
        </StyledButton>
    );
}

const StyledButton = styled(Button)(({theme, width}) => ({
    backgroundImage: "inherit !important",
    backgroundColor: "inherit !important",
    width: width ? `${width}px !important` : "inherit",
    textTransform: "none",
    cursor: "pointer",
    height: 100,
    fontWeight: 600,
    fontSize: "1.0rem",
    color: "black !important",
    "&:hover": {
        borderRadius: 0,
        color: "#efef",
    },
}));
