import React, {useContext} from "react";
import {styled} from "@mui/styles";
import Grid from "@mui/material/Grid";
import {Divider, Typography} from "@mui/material";
import PlatformSpace from "../../components/platform/platformSpace";
import PlatformButton from "../../components/platform/platformButton";
import CartContext from "./cartContext";
import PlatformNumberFormat from "../../components/platform/platformNumberFormat";
import {isDesktop, isMobile} from "react-device-detect";
import {useHistory} from "react-router-dom";
import formatToUSD from "../../components/util/moneyUtil";

export default function CartTotals(props) {
    const history = useHistory();
    const {cart} = useContext(CartContext);

    const onCompleteReservation = () => {
        history.push("/checkout")
    }

    return (
        <div style={{marginLeft: isMobile ? "0" : "45%"}}>
            <Grid container>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={9}>
                    <Grid container>
                        <Grid item xs={8}>
                            <SubTotalText variant="h8">
                                Subtotal
                            </SubTotalText>
                        </Grid>
                        <Grid item xs={4} container justifyContent="flex-end">
                            <SubTotalText variant="h8">
                                {formatToUSD(cart?.baseAmount)}
                            </SubTotalText>
                        </Grid>
                        <Grid item xs={7}>
                            <SubTotalText variant="h8">
                                Shipping
                            </SubTotalText>
                        </Grid>
                        <Grid item xs={5} container justifyContent="flex-end">
                            <SubTotalText variant="body5">
                                {(cart.serviceToken !== "free_shipping" && cart.shippingAmount > 0 && cart.shippingAmount) && formatToUSD(cart.shippingAmount)}
                                {cart.serviceToken !== "free_shipping" && cart.shippingAmount === 0 && "-"}
                                {(cart.serviceToken === "free_shipping") && "Free"}
                                {(!cart.serviceToken && !cart.shippingAmount) && "-"}
                            </SubTotalText>
                        </Grid>
                        <Grid item xs={8}>
                            <SubTotalText variant="h8">
                                Estimated tax
                            </SubTotalText>
                        </Grid>
                        <Grid item xs={4} container justifyContent="flex-end">
                            <SubTotalText variant="h8">
                                {formatToUSD(cart?.taxAmount)}
                            </SubTotalText>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={9}>
                    <Grid item xs={12}>
                        <PlatformSpace height={15}/>
                        <Divider/>
                        <PlatformSpace height={15}/>
                    </Grid>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="h5" style={{fontWeight: 500, textTransform: "none"}}>
                                Total
                            </Typography>
                        </Grid>
                        <Grid item xs={4} container justifyContent="flex-end">
                            <Typography variant="h5" style={{fontWeight: 500, textTransform: "none"}}>
                                <PlatformNumberFormat value={cart?.cartTotalAmount}/>
                            </Typography>
                        </Grid>
                        <PlatformSpace height={30}/>
                        {isDesktop &&
                            <Grid item xs={12} container justifyContent="flex-end">
                                <PlatformButton size="lg" onClick={onCompleteReservation}>
                                    Checkout
                                </PlatformButton>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            {isMobile &&
                <Grid item xs={12} container justifyContent="flex-end">
                    <PlatformButton size="lg" onClick={onCompleteReservation}>
                        Checkout
                    </PlatformButton>
                </Grid>
            }
            <PlatformSpace height={50}/>
        </div>
    );
}

const SubTotalText = styled(Typography)(({ theme }) => ({
    fontWeight: "400 !important"
}));