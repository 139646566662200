import React, {useCallback, useContext, useEffect, useState} from "react";
import CheckoutAPI from "./checkoutAPI";
import {splitCreditCardDate} from "../../components/util/stringUtil";
import {useHistory} from "react-router-dom";
import CartContext from "../cart/cartContext";
import PlatformFormContext from "../../components/platform/platformFormContext";
import CartApi from "../cart/cartApi";
import {debounce} from "lodash";
import {isMobile} from "react-device-detect";

export const CheckoutContext = React.createContext({});
export default CheckoutContext;

export const CheckoutContextProvider = ({children}) => {
    const {getForm, getFormValue, setFormValue, addChangeHandler, getFormValueDTO} = useContext(PlatformFormContext);
    const {emptyCart, updateCart} = useContext(CartContext);
    const history = useHistory();
    const [wizardStep, setWizardStep] = useState(1);
    const [processing, setProcessing] = useState(false);
    const [cartChecksum, setCartChecksum] = useState(null);
    const [couponError, setCouponError] = useState(null);
    const [shippingQuote, setShippingQuote] = useState([]);
    const [discount, setDiscount] = useState(null);
    const [loadingShipping, setLoadingShipping] = useState(false);
    const [addressValidated, setAddressValidated] = useState(null);
    const [recommendedAddress, setRecommendedAddress] = useState(null);
    const [recommendedAddressOpen, setRecommendedAddressOpen] = useState(false);
    const [defaultServiceToken, setDefaultServiceToken] = useState(null);

    useEffect(() => {
        if (wizardStep > 1) {
            if (!isMobile) {
                refreshCart();
            }
        }
    }, [wizardStep]);

    useEffect(() => {
        if (isMobile) {
            const addressFields = ["country", "state", "street1", "street2", "city", "zip"];
            addChangeHandler((changedField, groupBy) => {
                if (changedField && addressFields.indexOf(changedField.name) > -1 && groupBy === "shippingAddress") {
                    onValidateAddressAndCheckQuote((code) => {
                        if (code === "RECOMMENDATION") {
                            setRecommendedAddressOpen(true);
                        } else if (code === "VALID") {
                            setShippingQuote([]);
                            onShippingQuote();
                        }
                    })
                }
            });
        }
    }, []);

    const onWizardStep = (step) => {
        if (wizardStep === 1) {
            onValidateAddressAndCheckQuote((code) => {
                if (code === "RECOMMENDATION") {
                    setRecommendedAddressOpen(true);
                } else if (code === "VALID") {
                    setShippingQuote([]);
                    onShippingQuote();
                    setWizardStep(step);
                }
            })
        } else {
            if (step === 2 && addressValidated === "VALID") {
                onShippingQuote();
            }
            setWizardStep(step);
        }
    }

    const onValidateAddressAndCheckQuote = (callback) => {
        let shippingAddress = getFormValueDTO("shippingAddress");

        if (!validateAddressFields(shippingAddress)) {
            CheckoutAPI.validateAddress(shippingAddress, (result) => {
                if (result.code === "VALID") {
                    setAddressValidated("VALID");
                    setRecommendedAddress(result.recommendedAddress);
                } else if (result.code === "RECOMMENDATION") {
                    setShippingQuote([]);
                    setRecommendedAddress(result.recommendedAddress);
                    setAddressValidated("RECOMMENDATION");
                } else if (result.code === "INVALID") {
                    setShippingQuote([]);
                    setRecommendedAddress(null);
                    setAddressValidated("INVALID");
                }
                if (typeof callback === "function") {
                    callback(result.code, result.recommendedAddress);
                }
            })
        } else {
            setAddressValidated("INVALID");

            if (typeof callback === "function") {
                callback("INVALID");
            }
        }
    }

    const onAcceptRecommendation = () => {
        let shippingAddress = getFormValueDTO("shippingAddress");
        shippingAddress.street1 = recommendedAddress.street1;
        shippingAddress.city = recommendedAddress.city;
        shippingAddress.state = recommendedAddress.state;
        shippingAddress.zip = recommendedAddress["zip"];

        setFormValue("shippingAddress", mapDTOToAddress(shippingAddress), "shippingAddress");

        setRecommendedAddress(null);
        setRecommendedAddressOpen(false);
        setAddressValidated("VALID");
        setShippingQuote([]);
        onShippingQuote();
        setWizardStep(wizardStep + 1);

        refreshCart();
    }

    const onRejectRecommendation = () => {
        setRecommendedAddressOpen(false);
    }

    const onReloadForm = () => {
        CartApi.getCart((fetchedCart) => {
            const cart = fetchedCart?.cart;
            const billingAddressType = cart.billingAddressType || "SHIPPING_ADDRESS"
            setFormValue("couponCode", cart.couponCode);
            setFormValue("serviceToken", getFormValue("serviceToken"));
            setFormValue("email", cart.email);
            setFormValue("billingAddressType", billingAddressType);
            setFormValue("paymentType", cart.paymentType || "CREDIT_CARD");
            setFormValue("shippingAddress", mapDTOToAddress(cart.shippingAddress));
            if (billingAddressType === "SHIPPING_ADDRESS") {
                setFormValue("billingAddress", mapDTOToAddress(cart.shippingAddress));
            } else {
                setFormValue("billingAddress", mapDTOToAddress(cart.billingAddress));
            }
            onValidateAddressAndCheckQuote();
            // setFormValue("cardNumber", "1212 1212 1212 1212");
            // setFormValue("cardholder", "Paul Zain");
            // setFormValue("expirationDate", "12/24");
            // setFormValue("cvv", "123");
        });
    }

    const onUpdateDiscount = () => {
        refreshCart((cart) => {
            onShippingQuote();
        });
    }

    const onClearDiscount = () => {
        setFormValue("couponCode", "");
        setFormValue("serviceToken", "");
        setTimeout(() => {
            setDiscount(null);
            refreshCart((cart) => {
                onShippingQuote();
            });
        }, 350)
    }

    const refreshCart = (callback) => {
        let shippingAddress = getFormValueDTO("shippingAddress");
        let billingAddress = getFormValueDTO("billingAddress");

        updateCart({
            couponCode: getFormValue("couponCode"),
            serviceToken: getFormValue("serviceToken"),
            email: getFormValue("email"),
            billingAddressType: getFormValue("billingAddressType"),
            paymentType: getFormValue("paymentType"),
            shippingAddress: shippingAddress,
            billingAddress: billingAddress
        }, (success, cart) => {
            setCartChecksum(cart.checksum);
            if (cart.errors && cart.errors.length > 0) {
                setCouponError(cart.errors[0]);
            } else {
                setCouponError(null);
            }
            if (cart?.cart?.discountAmount > 0 && cart?.cart?.couponCode) {
                setDiscount({
                    amount: cart.cart.discountAmount, name: cart.cart.couponName
                })
            }
            if (typeof callback === "function") {
                callback(cart.cart);
            }
        })
    }

    const mapDTOToAddress = (data) => {
        if (!data) {
            //console.error('Data is null or undefined.', data);
            return [];
        }

        const keysToInclude = ["country", "state", "firstName", "lastName", "street1", "city", "zip", "phone"];

        return keysToInclude.map(key => {
            return {
                name: key, value: data[key] || '' // Use an empty string for null or undefined values
            };
        });
    }

    const onCheckout = () => {
        const payload = getForm();
        const rate = shippingQuote.rates.find((rate) => rate.serviceToken === payload?.serviceToken);

        if (payload.serviceToken === null || !rate) {
            setWizardStep(2);
            return;
        }

        setProcessing(true);

        refreshCart((cart) => {
            CheckoutAPI.checkout(convertPayload(payload), (data) => {
                const {order} = data;

                history.push({
                    pathname: `/order/receipt`, state: {
                        order: order
                    }
                })

                emptyCart();
            }, (err) => {
                history.push("/catalog")
            })
        })
    }

    const onShippingQuote = useCallback(debounce((callback) => {
        // if (!addressValidated || addressValidated !== "VALID") {
        //     if (typeof callback === "function") {
        //         callback([]);
        //     }
        //     return;
        // }
        setLoadingShipping(true);

        const formResult = getForm();
        if (formResult) {
            const {shippingAddress} = formResult;

            if (Array.isArray(shippingAddress)) {
                const result = {};
                for (let item of shippingAddress) {
                    result[item.name] = item.value;
                }
                result["country"] = "US"; // Assuming default country as "US"

                CheckoutAPI.getShippingRates(result, (shippingQuoteNew) => {
                    setShippingQuote(shippingQuoteNew);
                    const serviceToken = getFormValue("serviceToken");

                    if (!serviceToken || serviceToken.length === 0) {
                        const defaultServiceToken = shippingQuoteNew && shippingQuoteNew?.rates?.length > 0 ?
                          shippingQuoteNew.rates[0].serviceToken : null;
                        setFormValue("serviceToken", defaultServiceToken)
                        setDefaultServiceToken(defaultServiceToken);
                    } else {
                        setDefaultServiceToken(serviceToken);
                    }

                    if (typeof callback === "function") {
                        callback(shippingQuoteNew);
                    }

                    setLoadingShipping(false);
                });
            } else if (typeof callback === "function") {
                callback([]);
                setLoadingShipping(false);
            } else {
                console.warn("No callback specified");
            }
        }
    }, 1200), [getForm, setShippingQuote]); // Dependencies for useCallback

    function convertPayload(payload) {
        const creditCardExpiration = splitCreditCardDate(payload.expirationDate)

        return {
            creditCard: {
                cardNumber: payload.cardNumber,
                cardholder: payload.cardholder,
                cvv: payload.cvv,
                expirationMonth: creditCardExpiration.month,
                expirationYear: creditCardExpiration.year,
            }, optIn: payload.optIn, shipping: {
                serviceToken: getFormValue("serviceToken"), shipmentId: shippingQuote.shipmentId
            }
        };
    }

    function validateAddressFields(data) {
        // Check if data is null or not an object
        if (typeof data !== 'object' || data === null) {
            return {_error: 'Input data must be an object and cannot be null'};
        }

        // List of required fields
        const fields = ['firstName', 'lastName', 'street1', 'city', 'state', 'zip'];

        // Object to hold validation errors
        const errors = {};

        // Regular expressions for validation
        const zipCodeRegex = /^\d{5}(-\d{4})?$/;
        const stateRegex = /^[A-Z]{2}$/;

        // Check each field for existence, type, and content
        fields.forEach(field => {
            if (!(field in data)) {
                // Field is missing
                errors[field] = 'Field is required';
            } else if (typeof data[field] !== 'string') {
                // Field is not a string
                errors[field] = 'Field must be a string';
            } else if (data[field].trim() === '') {
                // Field is empty or just whitespace
                errors[field] = 'Field cannot be empty';
            } else {
                // Additional specific field validations
                switch (field) {
                    case 'zip':
                        if (!zipCodeRegex.test(data[field])) {
                            errors[field] = 'Zip code must be 5 digits or 9 digits in the format XXXXX-XXXX';
                        }
                        break;
                    case 'state':
                        if (!stateRegex.test(data[field])) {
                            errors[field] = 'State must be a valid 2-letter US state abbreviation';
                        }
                        break;
                    case 'street1':
                        if (data[field].trim().length < 5) {
                            errors[field] = 'Street address is too short';
                        }
                        break;
                }
            }
        });

        // If there are any errors, return them, otherwise return null indicating success
        return Object.keys(errors).length > 0 ? errors : null;
    }

    return (<CheckoutContext.Provider
        value={{
            wizardStep,
            setWizardStep,
            processing,
            setProcessing,
            shippingQuote,
            couponError,
            cartChecksum,
            discount,
            loadingShipping,
            addressValidated,
            onShippingQuote,
            recommendedAddress,
            setRecommendedAddress,
            recommendedAddressOpen,
            setRecommendedAddressOpen,
            defaultServiceToken,
            onWizardStep,
            onAcceptRecommendation,
            onRejectRecommendation,
            onUpdateDiscount,
            onReloadForm,
            onClearDiscount,
            onCheckout,
            refreshCart,
        }}
      >
          <>{children}</>
      </CheckoutContext.Provider>);
}