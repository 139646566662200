import React from 'react';
import {Box, Typography} from '@mui/material';
import PublicLayout from "../../../components/layout/publicLayout";
import {isMobile} from "react-device-detect";
import PlatformSpace from "../../../components/platform/platformSpace";
import PlatformMarginBox from "../../../components/platform/platformMarginBox";
import BlogWithMoodBanner from "../../../components/shared/blog/blogWithMoodBanner";

export default function BlogOurCannabinoids() {
    return (
        <PublicLayout>
            <BlogWithMoodBanner
                title="Cannabinoids: Nature's Complex Chemistry in Cannabis"
                image={`${process.env.PUBLIC_URL}/images/hh/WhatAreCannabinoids.jpg`}
            />
            <PlatformSpace height={isMobile ? 30 : 60}/>
            <PlatformMarginBox>
                <Box flex={1}>
                    <Typography variant="h5" gutterBottom>
                        Cannabinoids: The Diverse Molecules Behind Cannabis' Many Effects
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="body1" gutterBottom>
                        Think of cannabinoids as the diverse attendees at a molecular soiree within the cannabis plant, each with its unique chemical signature and effect on the human psyche and physiology. THC, for instance, is the life of the party, binding to brain receptors to ignite that "high" sensation, while CBD prefers a low-key presence, providing a soothing aura without the buzz. Meanwhile, CBN might be the perfect companion for a night in, coaxing you into sleep.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        The Discovery of Cannabinoids
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Cannabis has been a staple in human use for ages, but the 1960s brought a scientific revolution that unveiled THC and laid the groundwork for identifying other cannabinoids and the body's own endocannabinoid system. This system, much like a lock and key mechanism, allows cannabinoids to influence a spectrum of states and sensations.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        The Cannabinoids Taking Center Stage Today
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Out of the hundred-plus known cannabinoids, here's a rundown of the most noteworthy ones:

                        <ul>
                            <li>THC: The chief cannabinoid behind the cannabis "high," split into Delta-9 for its potency and Delta-8 for a milder experience.</li>
                            <li>THCa: THC's non-psychoactive forerunner that transforms upon heating.</li>
                            <li>THC-P and THC-V: Variants of THC, the former known for heightened effects and the latter dubbed "diet weed."</li>
                            <li>CBD: The calming agent without the intoxication.</li>
                            <li>CBN: The less potent, sedative cannabinoid.</li>
                            <li>CBG: The "mother" cannabinoid, a precursor to many others.</li>
                            <li>CBC: The binding element enhancing cannabinoid synergy.</li>
                            <li>HHC and HHC-P: THC analogs with varying effects and potencies.</li>
                        </ul>
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        Terpenes: The Aromatic Symphony in Cannabis
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Terpenes are aromatic compounds found in an abundance of plants, including cannabis, as well as many fruits and herbs. They give cannabis its signature spectrum of smells and tastes that range from the forest-like fragrance of pine to the bright notes of citrus and even the distinctive tang reminiscent of hops. Beyond their sensory appeal, terpenes serve a protective role for plants, functioning as natural deterrents to herbivores, shields against disease, and lures for beneficial pollinators.

                        When terpenes interact with cannabinoids, they participate in a dynamic relationship known as the "entourage effect." This interaction may amplify or alter the properties of each other, potentially heightening the therapeutic qualities of the cannabis plant.                </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" gutterBottom>
                        The Cannabinoid Detection Conundrum
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        While THC is a staple in drug tests, CBD typically evades detection. Yet, various factors dictate the presence of cannabinoids in the body:

                        <ul>
                            <li>The type of cannabinoid: THC outlasts CBD in detectability.</li>
                            <li>Consumption method: Smoking shows up quicker than edibles.</li>
                            <li>Usage frequency: Habitual use results in longer traceability.</li>
                            <li>Metabolism and body composition: The faster the metabolism and the less body fat, the quicker the clearance.</li>
                            <li>Hydration: Staying hydrated aids in flushing out cannabinoids.</li>
                        </ul>

                        Typically, expect cannabinoids to stay in saliva for up to a day, in blood for a couple of days, in urine for up to a month, and in hair for around three months. These are approximations, with individual experiences varying widely.
                    </Typography>
                </Box>
            </PlatformMarginBox>
            <PlatformSpace height={isMobile ? 30 : 60}/>
        </PublicLayout>
    );
};
