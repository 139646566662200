import {createTheme} from '@mui/material/styles';
import {isMobile} from "react-device-detect";

/*
theme.components = {
  ...theme.components,
  MuiGlobalStyles: {
    styleOverrides: {
      // Target the specific MUI class for the FormControlLabel's label
      '.MuiFormControlLabel-label': {
        color: `${theme.custom.primaryTextColor} !important`, // Apply the !important here
      },
    },
  },
};
 */
const custom = {
  primaryTextColor: "rgba(9,45,33,0.9)",
  primaryTextInverseColor: "rgba(255,255,255,0.9)",
  pageMargins: isMobile ? "0 15px 0 15px" : "0 80px 0 80px",
  pageWidth: "100vw"
};

const theme = createTheme({
  custom,
  transitions: {
    duration: {
      enteringScreen: 2000,
      leavingScreen: 100,
    }
  },
  typography: {
    allVariants: {
      fontFamily: "Poppins !important",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1.125rem",
      color: custom.primaryTextColor,
      fontFamily: "Poppins !important",
    },
    body2: {
      fontWeight: 700,
      color: custom.primaryTextColor
    },
    body3: {
      fontWeight: 600,
      color: custom.primaryTextColor,
      fontSize: "1.1rem",
      fontFamily: "Poppins !important",
    },
    body4: {
      fontWeight: 600,
      color: custom.primaryTextColor,
      fontSize: "0.9rem",
      fontFamily: "Poppins !important",
    },
    body5: {
      fontWeight: 500,
      color: custom.primaryTextColor,
      fontSize: "0.8rem",
      fontFamily: "Poppins !important",
    },
    h1: {
      fontWeight: 700,
      color: custom.primaryTextColor,
      fontFamily: "Poppins !important",
    },
    h2: {
      fontWeight: 700,
      color: custom.primaryTextColor,
      fontFamily: "Poppins !important",
    },
    h3: {
      fontWeight: 700,
      color: custom.primaryTextColor,
      fontFamily: "Poppins !important",
    },
    h4: {
      fontWeight: 700,
      color: custom.primaryTextColor,
      textTransform: "none",
      fontFamily: "Poppins !important",
    },
    h5: {
      fontWeight: 700,
      fontSize: 32,
      color: custom.primaryTextColor,
      textTransform: "none",
      fontFamily: "Poppins !important",
    },
    h6: {
      fontWeight: 700,
      color: custom.primaryTextColor,
      textTransform: "none",
      letterSpacing: 0,
      fontFamily: "Poppins !important",
    },
    h7: {
      fontWeight: 600,
      fontFamily: "Poppins !important",
      color: custom.primaryTextColor,
      textTransform: "none",
      fontSize: "1.3rem",
    },
    h8: {
      fontWeight: 600,
      color: custom.primaryTextColor,
      textTransform: "none",
      fontSize: "1.1rem",
      fontFamily: "Poppins !important",
    },
    button: {
      fontWeight: 600,
      textTransform: "none",
      letterSpacing: 0,
      fontSize: "1.1rem"
    }
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          color: "rgb(112, 122, 131) !important",
          fontWeight: 400
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "rgba(170,224,170,0.3) !important"
          },
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(59,42,84)",
          borderRadius: "20px"
        }
      }
    },
    MuiCollapse: {
      styleOverrides: {
        container: {
          transitionDuration: 0
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "none",
          color: "black",
          boxShadow: "none",
          "&.Mui-expanded": {
            marginTop: "0 !important"
          },
        }
      }
    },
    PrivateSwitchBase: {
      styleOverrides: {
        input: {
          color: custom.primaryTextColor, // Customize as needed
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: custom.primaryTextColor
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "5px !important"
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "rgb(59,42,84)",
          marginTop: 15,
          marginBottom: 15
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          "&.Mui-expanded": {
            minHeight: "48px !important"
          },
        },
        content: {
          color: custom.primaryTextColor,
          fontWeight: "600 !important",
          fontSize: "1rem !important",
          margin: 0,
          "&.Mui-expanded": {
            margin: "0 !important",
          },
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: "0 !important"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "100%",
          borderRadius: 8,
          fontWeight: 600,
          fontSize: "1rem",
          padding: 8,
          color: custom.primaryTextInverseColor,
          backgroundImage: "linear-gradient(to right bottom, rgb(252, 60, 172), rgb(104, 44, 231))"
        }
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          padding: 0,
          border: "4px solid rgb(41, 31, 55)",
          borderRadius: 12,
          '& .MuiSvgIcon-root': {
            color: custom.primaryTextColor
          },
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 600,
          color: "black"
        },
        root: {
          marginTop: "0 !important",
          marginBottom: "0 !important"
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: "1rem",
          backgroundColor: "rgb(59,42,84)",
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: custom.primaryTextColor,
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "rgb(172,186,197)"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: custom.primaryTextColor,
            transition: "color .3s ease-out",
          },
          color: "rgb(112, 122, 131)",
          //color: "rgba(227,204,255,0.56)",
          fontWeight: 600,
          fontSize: "1.6rem !important",
          "&.Mui-selected": {
            transition: "color .3s ease-out",
            color: "black",
          },
        },
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: custom.primaryTextColor
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: "0 !important",
        },
        root: {
          padding: 0,
          borderRadius: 8,
          backgroundColor: "rgba(15, 81, 53, 0.075)"
        },
        input: {
          borderRadius: 8,
          padding: "10px !important",
          fontWeight: 600,
          fontFamily: "Poppins",
        }
      }
    },
    MuiImageListItemBar: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins"
        }
      }
    }
  },
  // palette: {
  //   primary: {
  //     main: '#556cd6',
  //   },
  //   secondary: {
  //     main: '#19857b',
  //   },
  //   error: {
  //     main: red.A400,
  //   },
  // },
});

export default theme;