import * as React from 'react';
import {useContext} from 'react';
import Drawer from '@mui/material/Drawer';
import PublicContext from "../publicContext";
import {styled} from "@mui/styles";
import {Divider, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Close} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import PlatformSpace from "../../platform/platformSpace";
import PlatformButton from "../../platform/platformButton";
import PublicContactContext from "./publicContactContext";
import PlatformTextField from "../../platform/platformTextField";
import {isMobile} from "react-device-detect";
import useScrollToTop from "../../hook/useScrollToTop";

export default function PublicContactHome() {
    const {showContact, setShowContact} = useContext(PublicContext);
    const {onSubmit, message, setMessage} = useContext(PublicContactContext);

    useScrollToTop(showContact);

    const onContact = () => {
        onSubmit();
    }

    const onContactClose = () => {
        setShowContact(false);
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setShowContact(open);

        if (!open) {
            setMessage(null);
        }
    };

    return (
        <Drawer
            transitionDuration={{
                appear: 225,
                enter: 225,
                exit: 100
            }}
            PaperProps={{
                sx: {
                    boxShadow: "none",
                    backgroundColor: "rgba(0, 0, 0, 0) !important",
                    borderRadius: 4,
                    padding: 3,
                },
            }}
            sx={{
                zIndex: 10000
            }}
            anchor="right"
            open={showContact}
            onClose={toggleDrawer(false)}>
            {!message &&
                <ContactContainer>
                    <ContactHeader>
                        <Stack justifyContent="space-between" direction="row">
                            <Typography variant="h5" sx={{color: "black"}}>
                                Contact Us
                            </Typography>
                            <IconButton sx={{height: 25}} onClick={onContactClose}>
                                <Close sx={{color: "black"}} fontSize="medium" />
                            </IconButton>
                        </Stack>
                        <PlatformSpace height={30}/>
                        <Typography variant={isMobile ? "body3" : "h6"} sx={{color: "black"}}>
                            Got something on your mind? We're all ears and eager to hear from you! Share your
                            thoughts, and we'll jump right on it with enthusiasm and care. 🌼
                        </Typography>
                    </ContactHeader>
                    <ContactDivider/>
                    <ContactContent>
                        <PlatformTextField
                            label="Name"
                            name="name"
                            errorText="Name is required"
                            placeholder="First and last name"
                            required
                            fullWidth
                        />
                        <PlatformSpace height={10}/>
                        <PlatformTextField
                            label="Email"
                            name="email"
                            errorText="Email address is required"
                            placeholder="Email address"
                            email
                            required
                            fullWidth
                        />
                        <PlatformSpace height={10}/>
                        <PlatformTextField
                            label="Message"
                            multiline={true}
                            rows={3}
                            name="message"
                            errorText="Message is required"
                            placeholder="Your message"
                            required
                            fullWidth
                        />
                    </ContactContent>
                    <ContactDivider/>
                    <ContactContent>
                        <PlatformButton fullWidth={true} size="lg" onClick={onContact}>
                            Send Message
                        </PlatformButton>
                        <PlatformSpace height={30}/>
                    </ContactContent>
                </ContactContainer>
            }
            {message &&
                <ContactContainer>
                    <ContactHeader>
                        <Stack justifyContent="space-between" direction="row">
                            <Typography variant="h5" sx={{color: "black"}}>
                                We'll be in touch!
                            </Typography>
                            <IconButton sx={{height: 25}} onClick={onContactClose}>
                                <Close sx={{color: "black"}} fontSize="medium" />
                            </IconButton>
                        </Stack>
                        <PlatformSpace height={10}/>
                    </ContactHeader>
                    <ContactDivider/>
                    <ContactContent>
                        <Typography variant="h7" sx={{color: "black"}}>
                            We're thrilled you've reached out to us! Your thoughts and concerns mean the world to us,
                            and we promise to address them with care and speed. <br/><br/>Let's make things right together!
                        </Typography>
                    </ContactContent>
                </ContactContainer>
            }
        </Drawer>
    );
}

const ContactHeader = styled('div')(({ fullWidth }) => ({
    padding: "30px 25px 10px 25px"
}));

const ContactContent = styled('div')(({ fullWidth }) => ({
    padding: "10px 25px 10px 25px"
}));

const ContactDivider = styled(Divider)(({ fullWidth }) => ({
    borderColor: "rgba(0, 0, 0, 0.1) !important"
}));

const ContactContainer = styled('div')(({ fullWidth }) => ({
    minWidth: isMobile ? "88vw" : "25vw",
    maxWidth:  isMobile ? "88vw" : "25vw",
    maxHeight: 1000,
    minHeight: 500,
    backgroundImage: "linear-gradient(to top, rgb(255, 255, 255), rgba(15, 81, 53, 0.3))",
    backgroundColor: "rgba(255, 255, 255, 255) !important",
    borderRadius: 20
}));