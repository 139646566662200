import getImageUrl from "./getImageUrl";

const cartKey = "shoppingCart";
const cartCookieExpiration = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
const cartIDCookieKey = "Cart-ID";
const cartIDExpiration = 365 * 24 * 60 * 60 * 1000; // 365 days in milliseconds

// Helper method to detect if Local Storage is supported
export function isLocalStorage() {
    try {
        localStorage.setItem("test", "test");
        localStorage.removeItem("test");
        return true;
    } catch (e) {
        return false;
    }
}

export function calculateTotalCartItems(cart) {
    // Check if cart is null or if cartItems is null or not an array
    if (!cart || !Array.isArray(cart.cartItems)) {
        return 0;
    }

    const totalItems = cart.cartItems.reduce((total, currentItem) => {
        // Check if currentItem is an object and has a quantity property
        if (currentItem && typeof currentItem.quantity === 'number') {
            return total + currentItem.quantity;
        }
        return total;
    }, 0);

    return totalItems;
}

// Helper method to detect if Cookies are the fallback
export function isCookies() {
    return !isLocalStorage();
}

// Function to save cart to storage
export function saveCart(cart) {
    const cartJSON = JSON.stringify(cart);

    if (isLocalStorage()) {
        localStorage.setItem(cartKey, cartJSON);
    } else if (isCookies()) {
        // Expires in specified duration
        const date = new Date();
        date.setTime(date.getTime() + cartCookieExpiration);
        const expires = "; expires=" + date.toUTCString();
        document.cookie = cartKey + "=" + cartJSON + expires + "; path=/";
    }
}

export function getCartImageUrl(cartItem) {
    return getImageUrl(cartItem, 0);
}

// Function to load cart from storage
export function loadCart() {
    let cartJSON = "";

    if (isLocalStorage()) {
        cartJSON = localStorage.getItem(cartKey);
    } else if (isCookies()) {
        const nameEQ = cartKey + "=";
        const ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) cartJSON = c.substring(nameEQ.length, c.length);
        }
    }

    return cartJSON ? JSON.parse(cartJSON) : { cartItems: [] };
}

// Function to add item to cart
export function addItemToCart(item, cart) {
    cart.cartItems.push(item);
    saveCart(cart);
    return cart;
}

// Function to remove item from cart by index
export function removeItemFromCart(cartItem, cart) {
    const index = cart.cartItems.findIndex(item => item.id === cartItem.id);

    if (index !== -1) {
        cart.cartItems.splice(index, 1);
        saveCart(cart);
    }

    return cart;
}

// Function to clear cart
export function clearCart() {
    const emptyCart = { cartItems: [] };
    saveCart(emptyCart);
}


// Helper function to generate a secure Cart ID using the Web Crypto API
export function generateSecureCartID() {
    const array = new Uint8Array(64);
    window.crypto.getRandomValues(array);
    const id = Array.from(array, b => b.toString(16).padStart(2, "0")).join("");
    return id;
}

// Helper method to set a cookie
export function setCookie(name, value, expiration) {
    const date = new Date();
    date.setTime(date.getTime() + expiration);
    const expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + value + expires + "; path=/";
}

// Helper method to get a cookie by name
export function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

// Function to manage Cart ID cookie
export function manageCartIDCookie() {
    let cartID = getCookie(cartIDCookieKey);

    if (!cartID) {
        cartID = generateSecureCartID();
    }

    // Renew or set the Cart-ID cookie
    setCookie(cartIDCookieKey, cartID, cartIDExpiration);

    return cartID;
}