import React, {useContext} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import {Link, Stack} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import {styled} from "@mui/styles";
import {ORGANIZATION} from "../../../components/constants/organization";
import PublicContext from "../../../components/layout/publicContext";

export default function CatalogItemMainFAQ() {
    const {onContact} = useContext(PublicContext);

    const faqs = [
        {
            question: "How do you create the different effects?",
            answer: "Different cannabis strains can induce different effects. We curate our selection based on the cannabinoid profiles and terpenes which contribute to the overall effect."
        },
        {
            question: "Lab testing",
            answer: "All our products undergo rigorous lab testing to ensure purity and potency. We test for cannabinoid content, terpenes, pesticides, and contaminants."
        },
        {
            question: "Will this show up on a drug test?",
            answer: "THC can remain in your system for varying periods depending on usage. It is possible for our products containing THC to show up on a drug test."
        },
        {
            question: "Getting high: what our cannabis feels like",
            answer: "The experience can vary greatly depending on the strain and your personal biochemistry. Generally, it can range from feeling relaxed and euphoric to energized and creative."
        },
    ];

    const StyledIcon = styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '64px',
        minHeight: '64px',
        borderRadius: '50%',
        background: 'linear-gradient(135deg, #f0f0f0, #e0e0e0)',
        marginRight: '1rem',
        '& .MuiSvgIcon-root': {
            fontSize: '2rem',
        },
    }));

    return (
        <Container maxWidth="lg">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 10,
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <Typography variant="h4" gutterBottom sx={{ paddingLeft: 2 }}>
                        FAQs
                    </Typography>
                    {faqs.map((faq, index) => (
                        <Accordion key={index}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{faq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body4">
                                    {faq.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: { xs: 'none', md: 'block' },
                    }}
                >
                    <Stack
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            direction: "column",
                            alignItems: 'left',
                            backgroundColor: '#f5f5f5',
                            padding: '16px',
                            gap: 5,
                        }}
                    >
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <StyledIcon>
                                <CallIcon/>
                            </StyledIcon>
                            <Box>
                                <Typography variant="h6">
                                    CALL US TOLL FREE
                                </Typography>
                                <Typography variant="body1">
                                    {ORGANIZATION.supportHours}<br />
                                    {ORGANIZATION.supportPhone}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <StyledIcon>
                                <EmailIcon/>
                            </StyledIcon>
                            <Box>
                                <Typography variant="h6">
                                    EMAIL
                                </Typography>
                                <Link href={`mailto:${ORGANIZATION.supportEmail}`} color="inherit" underline="none">
                                    {ORGANIZATION.supportEmail}
                                </Link>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <StyledIcon>
                                <ChatIcon/>
                            </StyledIcon>
                            <Box>
                                <Typography variant="h6">
                                    SEND A MESSAGE
                                </Typography>
                                <Link href="#" color="inherit" underline="none" onClick={onContact}>
                                    Click here
                                </Link>
                            </Box>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Container>
    );
}
